import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { AdminSeminar } from '../../gql/graphql.ts';
import { graphql } from '../../gql';
import { InputStateWrapper as HookedInput } from '../form-components/InputStateWrapper.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation } from 'wouter';
import { Button } from '@progress/kendo-react-buttons';
import {
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { Label } from '@progress/kendo-react-labels';
import { FieldWrapper } from '@progress/kendo-react-form';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { CheckboxStateWrapper } from '../form-components/CheckboxStateWrapper.tsx';
import { DateTimePickerStateWrapper } from '../form-components/DateTimePickerStateWrapper.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { NumericInputStateWrapper } from '../form-components/NumericInputStateWrapper.tsx';
import { EditorStateWrapper } from '../form-components/EditorStateWrapper.tsx';
import { cloneAndRemoveTypename } from '../../public/util.ts';
import { Input } from '../form-components/Input.tsx';
import { BackButton } from '../../shared/BackButton.tsx';

interface SeminarEditPageProps {
  companyId: string;
  seminarId?: string;
  btsSeminarId?: string;
}

const SeminarQuery = graphql(/* GraphQL */ `
  query seminar(
    $companyId: String!
    $seminarId: String
    $btsSeminarId: String
  ) {
    seminar(
      companyId: $companyId
      seminarId: $seminarId
      btsSeminarId: $btsSeminarId
    ) {
      id
      title
      startDate
      endDate
      registrationDate
      canceled
      locationText
      price
      price2
      price3
      price4
      priceText
      priceText2
      priceText3
      priceText4
      minParticipants
      maxParticipants
      currentParticipants
      description
      description2
      description3
      description4
      description5
      visible
      btsLocked
      passwords
      seminarNumber
      lecturers {
        name
      }
      images {
        blobId
        name
        size
      }
    }
  }
`);

const UpdateSeminarMutation = graphql(/* GraphQL */ `
  mutation updateSeminar(
    $companyId: String!
    $seminarId: String!
    $updatedValues: UpdateSeminarDto!
  ) {
    updateSeminar(
      companyId: $companyId
      seminarId: $seminarId
      updateValues: $updatedValues
    )
  }
`);

const RemoveImageSeminarMutation = graphql(/* GraphQL */ `
  mutation removeImage(
    $companyId: String!
    $seminarId: String!
    $blobId: String!
  ) {
    removeImage(companyId: $companyId, seminarId: $seminarId, blobId: $blobId)
  }
`);

export const SeminarEditPage: React.FC<SeminarEditPageProps> = ({
  companyId,
  seminarId,
  btsSeminarId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: SeminarQuery,
    variables: { companyId, seminarId, btsSeminarId },
    pause:
      companyId === undefined ||
      companyId === null ||
      (!seminarId && !btsSeminarId),
  });

  const { control, handleSubmit, reset, formState } = useForm<AdminSeminar>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'passwords',
  });

  useEffect(() => {
    if (!seminarId && btsSeminarId && data?.seminar.id) {
      window.history.replaceState(
        null,
        '',
        `/admin/seminars/edit/${data?.seminar.id}`,
      );
    }
  }, [seminarId, btsSeminarId, data?.seminar.id]);

  useEffect(() => {
    if (!fetching && data?.seminar) {
      const withoutTypename =
        data?.seminar && cloneAndRemoveTypename(data?.seminar);
      setFiles(
        withoutTypename?.images.map((img) => ({
          uid: img.blobId,
          extension: '.jpg',
          name: img.name,
          size: img.size,
          status: UploadFileStatus.Selected,
          progress: 0,
        })),
      );

      //delete images and id
      const formData = {
        ...withoutTypename,
        images: undefined,
        id: undefined,
        lecturers: undefined,
      };
      reset({
        ...formData,
        startDate: new Date(data.seminar.startDate),
        endDate: new Date(data.seminar.endDate),
        registrationDate:
          data.seminar.registrationDate &&
          new Date(data.seminar.registrationDate),
      });
    }
  }, [fetching]);

  const [, updateSeminar] = useMutation(UpdateSeminarMutation);
  const [, removeImage] = useMutation(RemoveImageSeminarMutation);
  const [, setLocation] = useLocation();

  const onFormSubmit = async (value: AdminSeminar) => {
    if (seminarId) {
      for (const file of files) {
        if (file.getRawFile && file.getRawFile()) {
          const formData = new FormData();
          formData.append('image', file.getRawFile());
          await fetch(`/api/seminar/${seminarId}/images/`, {
            method: 'POST',
            body: formData,
          });
        }
      }
      if (data?.seminar.images) {
        for (const image of data.seminar.images) {
          if (!files.find((f) => f.uid === image.blobId)) {
            await removeImage({
              seminarId: seminarId,
              companyId: companyId,
              blobId: image.blobId,
            });
          }
        }
      }
      updateSeminar({
        companyId,
        seminarId,
        updatedValues: {
          btsLocked: value.btsLocked,
          canceled: value.canceled,
          currentParticipants: value.currentParticipants,
          description: value.description,
          description2: value.description2,
          description3: value.description3,
          description4: value.description4,
          description5: value.description5,
          endDate: value.endDate,
          locationText: value.locationText,
          maxParticipants: value.maxParticipants,
          minParticipants: value.minParticipants,
          passwords: value.passwords,
          price: value.price,
          price2: value.price2,
          price3: value.price3,
          price4: value.price4,
          priceText: value.priceText,
          priceText2: value.priceText2,
          priceText3: value.priceText3,
          priceText4: value.priceText4,
          registrationDate: value.registrationDate,
          startDate: value.startDate,
          title: value.title,
          visible: value.visible,
        },
      }).then(() => {
        setLocation('/seminars');
      });
    }
  };

  const { isValid, isDirty } = formState;
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [isFilesDirty, setIsFilesDirty] = useState<boolean>(false);

  const onAddFile = (event: UploadOnAddEvent) => {
    setFiles(
      event.newState.map((files) => ({
        ...files,
        status: UploadFileStatus.Selected,
      })),
    );
    setIsFilesDirty(true);
  };

  const onRemoveFile = (event: UploadOnRemoveEvent) => {
    setFiles(event.newState);
    setIsFilesDirty(true);
  };

  const onRemoveUploadedFile = async (files: UploadFileInfo[]) => {
    // To approve to kendo, that the file with the uid is removed
    return { uid: files[0].uid };
  };

  return (
    <>
      <PageTitle title="Seminar bearbeiten">
        <div className="flex w-full justify-between">
          <BackButton />
          <a
            href={`/seminar/${seminarId}`}
            target="_blank"
            className="flex items-center gap-1.5 border-b border-transparent text-base text-zinc-800 hover:border-black hover:text-black"
          >
            Zur öffentlichen Seite{' '}
            <HeroIcon
              name="ArrowTopRightOnSquare"
              className="inline-block h-5 w-5 shrink-0"
            />
          </a>
        </div>
      </PageTitle>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend={`Allgemein`}
          description="Basisinformationen rund um das Seminar"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <CheckboxStateWrapper
              name={'canceled'}
              label={'Abgesagt'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'visible'}
              label={'Sichtbar'}
              control={control}
            />
            <CheckboxStateWrapper
              name={'btsLocked'}
              label={'BTS-Aktualisierungen sperren'}
              control={control}
            />
            <HookedInput
              name={'title'}
              label={'Seminartitel'}
              control={control}
              readOnly
            />
            <HookedInput
              name={'seminarNumber'}
              label={'Seminarnummer'}
              control={control}
              readOnly
            />
            <div className="grid grid-cols-3 gap-x-6">
              <DateTimePickerStateWrapper
                name={'startDate'}
                label={'Beginn'}
                control={control}
              />
              <DateTimePickerStateWrapper
                name={'endDate'}
                label={'Ende'}
                control={control}
              />
              <DateTimePickerStateWrapper
                name={'registrationDate'}
                label={'Anmeldeschluss'}
                control={control}
              />
            </div>
            {data?.seminar?.lecturers.map(
              (lecturer, i) =>
                lecturer.name && (
                  <Input
                    key={'lecturer' + i}
                    value={lecturer.name}
                    id={'lecturers'}
                    type={'text'}
                    label={i === 0 ? 'Dozent(in)' : undefined}
                    readOnly
                  />
                ),
            )}

            <HookedInput
              name={'locationText'}
              label={'Ort'}
              rules={{ required: 'Dieses Feld muss ausgefüllt werden!' }}
              control={control}
            />
            <div className="grid grid-cols-3 gap-x-6">
              <NumericInputStateWrapper
                name={'minParticipants'}
                label={'Minimale Teilnehmeranzahl'}
                control={control}
              />
              <NumericInputStateWrapper
                name={'maxParticipants'}
                label={'Maximale Teilnehmeranzahl'}
                control={control}
              />
              <NumericInputStateWrapper
                name={'currentParticipants'}
                label={'Aktuelle Teilnehmeranzahl'}
                control={control}
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset
          legend={`Preise`}
          description="Modifizieren Sie die verschiedenen Preise und ihre Beschreibungen"
        >
          <div className="grid grid-cols-[30%_1fr] gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <NumericInputStateWrapper
              name={'price'}
              format={'c2'}
              label={'Preis'}
              control={control}
            />
            <HookedInput
              name={'priceText'}
              label={'Preis Beschreibung'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price2'}
              label={'Preis 2'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText2'}
              label={'Preis Beschreibung 2'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price3'}
              label={'Preis 3'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText3'}
              label={'Preis Beschreibung 3'}
              control={control}
            />
            <NumericInputStateWrapper
              name={'price4'}
              label={'Preis 4'}
              format={'c2'}
              control={control}
            />
            <HookedInput
              name={'priceText4'}
              label={'Preis Beschreibung 4'}
              control={control}
            />
          </div>
        </Fieldset>
        <Fieldset
          legend={`Beschreibungen und Webseiten-Inhalt`}
          description="Die Beschreibungen und Bilder tauchen auf der öffentlichen Webseite für Teilnehmer und Interessenten auf."
        >
          <div className="grid grid-cols-[55%_1fr] md:col-span-9 [&_.k-form-field]:!mt-0">
            <div className="flex flex-col gap-4">
              <EditorStateWrapper
                name={'description'}
                label={'Beschreibung 1'}
                control={control}
              />
              <EditorStateWrapper
                name={'description2'}
                label={'Beschreibung 2'}
                control={control}
              />
              <EditorStateWrapper
                name={'description3'}
                label={'Beschreibung 3'}
                control={control}
              />
              <EditorStateWrapper
                name={'description4'}
                label={'Beschreibung 4'}
                control={control}
              />
              <EditorStateWrapper
                name={'description5'}
                label={'Beschreibung 5'}
                control={control}
              />
              <FieldWrapper
                className={`flex w-full flex-col self-start [&_.k-editor]:!rounded-md [&_.k-toolbar]:!rounded-t-md`}
              >
                <Label className="k-form-label mb-1">Bilder</Label>
                <Upload
                  accept="image/png, image/jpeg"
                  batch={false}
                  multiple={true}
                  withCredentials={false}
                  removeUrl={onRemoveUploadedFile}
                  files={files}
                  onAdd={onAddFile}
                  onRemove={onRemoveFile}
                />
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Für optimale Darstellung sollten die Bilder ein
                  Seitenverhältnis von 21:9 aufweisen und eine Breite von
                  mindestens 500 Pixel besitzen. Ein gutes Format wäre z.B. 1260
                  x 540 Pixel.
                </p>
              </FieldWrapper>
            </div>
          </div>
        </Fieldset>

        <Fieldset
          legend={`Passwörter`}
          description="Hier können Sie ein oder mehrere Passwörter definieren, mit denen Teilnehmer sich für das Seminar anmelden können. Ohne eines der Passwörter ist dann keine Anmeldung mehr möglich."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            {fields.map((field, i) => (
              <div key={i} className={'flex items-end justify-between gap-4'}>
                <HookedInput
                  wrapperClassName={'flex-1'}
                  key={field.id}
                  name={`passwords.${i}`}
                  control={control}
                  label={`Passwort ${i + 1}`}
                  rules={{ required: 'Passwort muss ausgefüllt sein!' }}
                />
                <Button
                  className="h-[38px] !shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                  type={'button'}
                  fillMode={'outline'}
                  themeColor={'secondary'}
                  onClick={() => remove(i)}
                  title="Entfernen"
                >
                  <HeroIcon name="Trash" className="block h-5 w-5" />
                </Button>
              </div>
            ))}
            <Button
              type={'button'}
              themeColor={'secondary'}
              className="self-start !shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
              onClick={() => append('')}
              title="Passwort hinzufügen"
            >
              <HeroIcon name="PlusCircle" className="block h-5 w-5" />
              <div>Passwort hinzufügen</div>
            </Button>
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || (!isDirty && !isFilesDirty)}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
