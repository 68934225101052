import { Helmet } from 'react-helmet-async';

interface PageTitleProps {
  title: string;
  className?: string;
  children?: React.ReactNode;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  className,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>
          {title} - {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>
      <div className="my-3 flex flex-col justify-between gap-5">
        <h1 className={`${className || ''}`}>{title}</h1>
        {children}
      </div>
    </>
  );
};
