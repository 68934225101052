import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql/index.ts';
import { MailTemplate } from '../../gql/graphql.ts';
import { MailTemplateEditor } from './MailTemplateEditor.tsx';

interface MailSettingsPageProps {
  companyId: string;
}

const MailSettingsParticipantTemplateQuery = graphql(/* GraphQL */ `
  query mailSevenDaysForLecturer($companyId: String!) {
    company(companyId: $companyId) {
      mailSettings {
        mailTemplateReminderSevenDaysForLecturer {
          subject
          template
        }
      }
    }
  }
`);

const UpdateParticipantTemplateQuery = graphql(/* GraphQL */ `
  mutation updateTemplateSevenDaysForLecturer(
    $companyId: String!
    $company: UpdateCompanyDto!
  ) {
    updateCompany(companyId: $companyId, company: $company) {
      mailSettings {
        mailTemplateReminderSevenDaysForLecturer {
          subject
          template
        }
      }
    }
  }
`);

export const ReminderSevenDaysForLecturerTemplateEditor: React.FC<
  MailSettingsPageProps
> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }, reExecuteQuery] = useQuery({
    query: MailSettingsParticipantTemplateQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCompany] = useMutation(UpdateParticipantTemplateQuery);

  const onFormSubmit = (values: MailTemplate) => {
    updateCompany({
      companyId,
      company: {
        mailSettings: { mailTemplateReminderSevenDaysForLecturer: values },
      },
    }).then(() => {
      // TODO: Check for errors and indicate success/failure to UI
      reExecuteQuery();
    });
  };

  return (
    <>
      {data?.company.mailSettings.mailTemplateReminderSevenDaysForLecturer && (
        <MailTemplateEditor
          companyId={companyId}
          template={
            data.company.mailSettings.mailTemplateReminderSevenDaysForLecturer
          }
          onSubmit={onFormSubmit}
        />
      )}
    </>
  );
};
