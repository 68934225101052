import { PageTitle } from './PageTitle.tsx';
import { graphql } from '../gql/index.ts';
import { useQuery } from 'urql';
import {
  Scheduler,
  MonthView,
  SchedulerItem,
  WeekView,
  DayView,
} from '@progress/kendo-react-scheduler';
import { Day } from '@progress/kendo-date-math';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Fieldset } from './form-components/Fieldset.tsx';
import { useCallback, useState } from 'react';
import { ModulePrivileges } from '../gql/graphql.ts';
import React from 'react';
import { getHslColorFromString } from '../shared/helpers/color.ts';

interface CalendarPageProps {
  companyId: string;
}

const CalenderEntriesQuery = graphql(/* GraphQL */ `
  query getCalendarEntries($companyId: String!) {
    calendarEvents(companyId: $companyId) {
      id
      begin
      end
      seminar {
        title
        id
      }
    }
  }
`);

const UserAvailabilityEventsQuery = graphql(/* GraphQL */ `
  query getAllAvailabilityEventsForUsers(
    $companyId: String!
    $userIds: [String!]!
  ) {
    getAllAvailabilityEventsForUsers(companyId: $companyId, userIds: $userIds) {
      id
      begin
      end
      userId
    }
  }
`);

const UsersQuery = graphql(/* GraphQL */ `
  query usersAndLecturersForCalendar($companyId: String!) {
    usersAndLecturersForCompany(companyId: $companyId) {
      lecturerId
      userId
      firstName
      lastName
      hasAccount
      privileges
    }
  }
`);

export const CalendarPage: React.FC<CalendarPageProps> = ({ companyId }) => {
  const [showSeminarLayer, setShowSeminarLayer] = useState(true);
  const [showAvailabilityLayer, setShowAvailabilityLayer] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<
    { userId: string; firstName: string; lastName: string }[]
  >([]);

  const [{ data: calendarEntriesData }] = useQuery({
    query: CalenderEntriesQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [{ data: availabilityData }] = useQuery({
    query: UserAvailabilityEventsQuery,
    variables: { companyId, userIds: selectedUsers.map((u) => u.userId) },
    pause:
      companyId === undefined || companyId === null || !selectedUsers.length,
  });

  const [{ data: usersData }] = useQuery({
    query: UsersQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const getDataForSelect = useCallback(() => {
    return usersData?.usersAndLecturersForCompany
      ?.filter(
        (user) =>
          user.hasAccount &&
          user.privileges?.includes(ModulePrivileges.Lecturer),
      )
      .map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: {
          ...user,
          color: user.userId && getHslColorFromString(user.userId),
        },
      }));
  }, [usersData]);

  const getDataForCalendar = () => {
    const result = [];
    if (showSeminarLayer) {
      result.push(
        ...(calendarEntriesData?.calendarEvents?.map((e) => ({
          id: e.id,
          start: new Date(e.begin),
          end: new Date(e.end),
          title: e.seminar.title,
          url: '/admin/seminars/edit/' + e.seminar.id,
        })) ?? []),
      );
    }
    if (
      showAvailabilityLayer &&
      availabilityData?.getAllAvailabilityEventsForUsers
    ) {
      result.push(
        ...availabilityData?.getAllAvailabilityEventsForUsers.map((e) => {
          const user = usersData?.usersAndLecturersForCompany.find(
            (u) => u.userId === e.userId,
          );

          const userColor = user?.userId && getHslColorFromString(user.userId);

          return {
            id: e.id,
            start: new Date(e.begin),
            end: new Date(e.end),
            title: `${user?.firstName} ${user?.lastName}`,
            ...(userColor ? { bgColor: userColor } : {}),
          };
        }),
      );
    }
    return result;
  };

  return (
    <>
      <PageTitle title="Planungskalender" />
      <div className="flex h-[90%] w-full flex-col rounded-md bg-white shadow">
        <Fieldset
          legend={`Kalender-Ebenen`}
          description="Bitte wählen Sie, welche Daten der Kalender darstellen soll."
          className="border-b-0"
        >
          <div className="flex flex-col items-start gap-2 md:col-span-9">
            <div className="itesm-center flex gap-2">
              <Checkbox
                id={'seminar-layer-checkbox'}
                onChange={() => {
                  setShowSeminarLayer((oldState) => !oldState);
                }}
                value={showSeminarLayer}
              />
              <div>Seminare</div>
            </div>
            <div className="flex w-1/2 items-center gap-2">
              <Checkbox
                id={'availability-layer-checkbox'}
                onChange={() => {
                  setShowAvailabilityLayer((oldState) => !oldState);
                }}
                value={showAvailabilityLayer}
              />
              <div>Dozentenverfügbarkeit</div>
              <MultiSelect
                size="small"
                data={getDataForSelect()}
                placeholder="Bitte Dozent(en) auswählen ..."
                textField={'label'}
                onChange={(e) => {
                  setSelectedUsers(e.target.value?.map((o) => o.value));
                }}
                tagRender={(tagData, li) => {
                  return React.cloneElement(
                    li,
                    {
                      ...li.props,
                      style: {
                        color: 'white',
                        backgroundColor: tagData?.data?.[0]?.value?.color,
                      },
                    },
                    li.props.children,
                  );
                }}
              />
            </div>
          </div>
        </Fieldset>
        <div className="h-full p-8">
          <Scheduler
            onDateChange={(event) => {
              console.log(event.value);
            }}
            data={getDataForCalendar()}
            height={'100%'}
            footer={() => <></>}
            item={(props) => {
              return (
                <SchedulerItem
                  {...props}
                  {...(props.dataItem.url
                    ? {
                        onClick: (e) =>
                          (window.location.href = e.target.props.dataItem.url),
                      }
                    : {})}
                  {...(props.dataItem.bgColor
                    ? {
                        style: {
                          ...props.style,
                          backgroundColor: props.dataItem.bgColor,
                        },
                      }
                    : {})}
                />
              );
            }}
            style={
              {
                '--kendo-font-size': '0.875em',
              } as React.CSSProperties
            }
            className="[&_.k-scheduler-body]:h-[calc(100%-50px)]"
          >
            <MonthView
              selectedDateFormat="{0:d}"
              selectedShortDateFormat="{0:d}"
            />
            <WeekView workWeekStart={Day.Monday} workWeekEnd={Day.Thursday} />
            <DayView
              slotDuration={60}
              slotDivisions={2}
              startTime={'07:00'}
              endTime={'19:00'}
              workDayStart={'07:00'}
              workDayEnd={'22:00'}
            />
          </Scheduler>
        </div>
      </div>
    </>
  );
};
