import { graphql } from '../gql/index.ts';
import { useMutation } from 'urql';
import { usePublicCompany } from './hooks/usePublicCompany.ts';

import { useForm } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { PublicProfileSidebar } from './PublicProfileSidebar.tsx';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { Loader } from '@progress/kendo-react-indicators';
import { EditorStateWrapper } from '../admin/form-components/EditorStateWrapper.tsx';
import { InputStateWrapper } from '../admin/form-components/InputStateWrapper.tsx';
import { useState } from 'react';

const UpdateProfileMutation = graphql(/* GraphQL */ `
  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {
    publicUpdateUser(companyId: $companyId, user: $user)
  }
`);

const DeleteProfileImageMutation = graphql(/* GraphQL */ `
  mutation deleteProfileImage {
    publicDeleteProfileImage
  }
`);

interface MyLecturerProfileProps {
  companyId: string;
}

interface LecturerProfileForm {
  vita: string;
  jobTitle: string;
}

export const MyLecturerProfile: React.FC<MyLecturerProfileProps> = ({
  companyId,
}) => {
  const [, updateProfile] = useMutation(UpdateProfileMutation);
  const [, deleteProfileImage] = useMutation(DeleteProfileImageMutation);

  const [publicCompany] = usePublicCompany();
  const { userInfo, refetchUserInfo } = useUserInfo();

  const profileImagePath = userInfo?.data?.companiesForSessionUser?.user
    .pathToProfileImage
    ? '/api' + userInfo?.data?.companiesForSessionUser?.user.pathToProfileImage
    : null;

  const [file, setFile] = useState<File | null>(null);
  const [fileShouldBeDeleted, setFileShouldBeDeleted] = useState(false);

  const showSidebar =
    publicCompany?.functionalSettings?.searchActive ||
    Boolean(publicCompany?.sites?.length) ||
    Boolean(publicCompany?.categories?.length);

  const { control, formState, handleSubmit, reset } =
    useForm<LecturerProfileForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      values: {
        vita: userInfo?.data?.companiesForSessionUser.user.vita || '',
        jobTitle: userInfo?.data?.companiesForSessionUser.user.jobTitle || '',
      },
    });

  const { isDirty, isSubmitting } = formState;

  return (
    <>
      <PublicProfileSidebar />
      <div
        className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
      >
        <h2>Dozentenprofil</h2>
        <div className="relative flex flex-col rounded-md border border-gray-200 bg-white p-6 md:p-8">
          <form
            className="grid grid-cols-3 grid-rows-[1fr_1fr_auto] gap-4"
            onSubmit={handleSubmit(async (formData) => {
              if (file) {
                const formData = new FormData();
                formData.append('profile-image', file);
                formData.append('companyId', companyId);

                await fetch('/api/user/profile-image', {
                  method: 'POST',
                  body: formData,
                });
              }

              if (fileShouldBeDeleted) {
                await deleteProfileImage({});
              }

              if (isDirty) {
                updateProfile({
                  companyId,
                  user: formData,
                }).then(() => {
                  refetchUserInfo();
                  reset();
                });
              }
            })}
          >
            <div className="row-span-2 flex flex-col">
              <Label editorId="profiledisplay" className="k-form-label mb-1">
                Profilbild
              </Label>
              <div className="flex w-full flex-1 flex-col items-center justify-center gap-2 rounded-md border border-gray-200 p-4">
                <div className="relative h-24 w-24 overflow-hidden rounded-full">
                  <button
                    onClick={() => {
                      if (file) {
                        setFile(null);
                      } else {
                        setFileShouldBeDeleted(true);
                      }
                    }}
                    type="button"
                    className="group absolute inset-0 flex items-center justify-center hover:cursor-pointer hover:bg-black/30"
                    title="Bild löschen"
                  >
                    <HeroIcon
                      name={'Trash'}
                      className="hidden h-8 w-8 text-white/80 group-hover:block"
                    />
                  </button>
                  {file ? (
                    <img
                      className="h-full w-full object-cover"
                      src={URL.createObjectURL(file)}
                    ></img>
                  ) : profileImagePath && !fileShouldBeDeleted ? (
                    <img
                      className="h-full w-full object-cover"
                      src={profileImagePath}
                    ></img>
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-300">
                      <HeroIcon
                        name={'UserCircle'}
                        variant="solid"
                        className="h-16 w-16 text-white/80"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-span-2 flex flex-col">
              <Label editorId="profileimage" className="k-form-label mb-1">
                Neues Bild hochladen
              </Label>

              <input
                type={'file'}
                id="profileimage"
                name="profileimage"
                accept={'image/png, image/jpeg'}
                className="k-input-solid block h-9 w-full cursor-pointer rounded-md border border-gray-200 text-sm file:h-full file:rounded-l-md file:border-none file:bg-gray-200 file:px-2 file:hover:bg-gray-300"
                onChange={(e) => {
                  e.preventDefault();

                  if (e.currentTarget?.files?.[0])
                    setFile(e.currentTarget?.files?.[0]);
                }}
              />
            </div>

            <InputStateWrapper
              name={'jobTitle'}
              label={'Berufsbezeichnung'}
              wrapperClassName="col-start-2 col-span-2"
              control={control}
            />
            <EditorStateWrapper
              containerClassName="col-span-3"
              name={'vita'}
              label={'Vita'}
              control={control}
            />

            <div className="pt-4">
              <Button
                disabled={isSubmitting || (!isDirty && !file)}
                type={'submit'}
                themeColor={'primary'}
                className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                title="Speichern"
              >
                {isSubmitting ? (
                  <Loader type={'pulsing'} className={'!text-white'} />
                ) : (
                  <HeroIcon name="CheckCircle" className="block h-5 w-5" />
                )}
                <div>Speichern</div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
