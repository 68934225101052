import { MailTemplate } from '../../gql/graphql.ts';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { InputStateWrapper as HookedInput } from '../form-components/InputStateWrapper.tsx';
import { TextareaStateWrapper } from '../form-components/TextareaStateWrapper.tsx';
import { Button } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { useMutation } from 'urql';
import { graphql } from '../../gql';
import { BackButton } from '../../shared/BackButton.tsx';

interface MailTemplateEditorProps {
  template: MailTemplate;
  companyId: string;
  onSubmit: (v: MailTemplate) => void;
}

const MjmlPreviewMutation = graphql(/* GraphQL */ `
  mutation previewMjml($companyId: String!, $mjmlTemplate: String!) {
    previewMjml(companyId: $companyId, mjmlTemplate: $mjmlTemplate) {
      body
    }
  }
`);

export const MailTemplateEditor: React.FC<MailTemplateEditorProps> = ({
  template,
  companyId,
  onSubmit,
}) => {
  const [{ data: dataPreview }, getPreview] = useMutation(MjmlPreviewMutation);
  const { control, formState, handleSubmit, reset, getValues } =
    useForm<MailTemplate>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });

  const onFormSubmit = (values: MailTemplate) => {
    onSubmit(values);
  };

  useEffect(() => {
    reset({
      subject: template.subject,
      template:
        template.template ??
        `<mjml><mj-body><mj-text> </mj-text></mj-body></mjml>`,
    });
    refreshPreview();
  }, [template]);

  const { isValid, isDirty } = formState;

  const refreshPreview = () => {
    getPreview({
      companyId,
      mjmlTemplate: getValues('template') ?? '',
    });
  };

  return (
    <>
      <PageTitle title="E-Mail Template editieren">
        <BackButton />
      </PageTitle>

      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-1 flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Vorlage bearbeiten"
          descriptionHtml="Die E-Mail Vorlagen und Betreffzeilen unterstützen die Template-Sprache MJML. Es stehen die folgenden Variablen zur Verfügung:<br/><br/>
          <ul class='text-sm'>
            <li>[[SEMINARNAME]]</li>
            <li>[[SEMINARNUMBER]]</li>
            <li>[[SEMINARDATE]]</li>
            <li>[[SEMINARLOCATION]]</li>
            <li>[[FORMTABLE]]</li>
          </ul>"
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
              <HookedInput
                name={'subject'}
                label={'Betreff'}
                placeholder="Betreff"
                control={control}
                rules={{ required: true }}
                required={true}
              />
              <TextareaStateWrapper
                name={'template'}
                label={'Vorlage'}
                placeholder="Hier MJML Vorlage einfügen"
                control={control}
                rows={30}
              />
            </div>
          </div>
        </Fieldset>
        <Fieldset
          legend="Vorschau"
          description="Hier kann die obige Vorlage sofort ausgegeben und angezeigt werden."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <Button
              type="button"
              onClick={refreshPreview}
              className="[&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            >
              <HeroIcon name="ArrowPath" className="block h-5 w-5 shrink-0" />
              <div>Vorschau aktualisieren</div>
            </Button>
            <iframe
              className={'h-[800px] w-full rounded-md'}
              // Without a unique key, the iframe will add a History item, when it's loaded, so the back button has to be clicked twice to get back to the last page
              key={Math.random()}
              srcDoc={dataPreview?.previewMjml?.body}
            ></iframe>
          </div>
        </Fieldset>
        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
