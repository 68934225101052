interface FieldsetProps {
  children?: React.ReactNode;
  legend?: React.ReactNode;
  description?: React.ReactNode;
  descriptionHtml?: TrustedHTML;
  className?: string;
}
export const Fieldset: React.FC<FieldsetProps> = ({
  children,
  legend,
  description,
  descriptionHtml,
  className,
}) => {
  return (
    <fieldset
      className={`grid grid-cols-1 gap-6 border-b border-zinc-200 p-8 md:grid-cols-12 ${className || ''}`}
    >
      {(legend || description) && (
        <span className="md:col-span-3">
          {legend && (
            <legend className={'!text-base !font-semibold'}>{legend}</legend>
          )}
          {description ||
            (descriptionHtml && (
              <p
                className="mt-1 text-sm leading-6 text-gray-600"
                {...(descriptionHtml
                  ? { dangerouslySetInnerHTML: { __html: descriptionHtml } }
                  : {})}
              >
                {description}
              </p>
            ))}
        </span>
      )}
      {children}
    </fieldset>
  );
};
