import { useMutation, useQuery } from 'urql';
import { graphql } from '../gql/index.ts';
import { Controller, useForm } from 'react-hook-form';
import {
  FunctionalSettings,
  SeminarOrderBy,
  VisualSettings,
} from '../gql/graphql.ts';
import { Button } from '@progress/kendo-react-buttons';
import React, { useEffect } from 'react';
import { Label } from '@progress/kendo-react-labels';
import {
  ColorPicker,
  ColorPickerChangeEvent,
} from '@progress/kendo-react-inputs';
import { FieldWrapper } from '@progress/kendo-react-form';
import {
  Editor,
  EditorChangeEvent,
  EditorTools,
} from '@progress/kendo-react-editor';
import { PageTitle } from './PageTitle.tsx';
import { Fieldset } from './form-components/Fieldset.tsx';
import { CheckboxStateWrapper } from './form-components/CheckboxStateWrapper.tsx';
import { DropdownStateWrapper } from './form-components/DropdownStateWrapper.tsx';
import { InputStateWrapper } from './form-components/InputStateWrapper.tsx';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { cloneAndRemoveTypename } from '../public/util.ts';
import { Input } from './form-components/Input.tsx';

const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  UnorderedList,
  OrderedList,
  FormatBlock,
  Unlink,
  InsertImage,
  Link,
} = EditorTools;

interface GeneralSettingsPageProps {
  companyId: string;
}

const CompanySettingsQuery = graphql(/* GraphQL */ `
  query companySettings($companyId: String!) {
    company(companyId: $companyId) {
      visualSettings {
        showTutor
        showEndOfRegistrationForSeminar
        showDescription
        showSeminarNumber
        showPrice
        showDate
        showLocation
        showImages
        showSignalLight
        backToDashboardActive
        backToMainActive
        primaryColor
        primaryTextColor
      }
      secret
      active
      domain
      functionalSettings {
        errorTextWrongPassword
        infoText
        passwordActive
        priceCalculation
        registrationActive
        searchActive
        seminarsOrderBy
        showPastSeminars
        waitlistActive
        disableUserAccounts
        showBookedOutSeminars
        cancelSignUpActive
      }
      mailSettings {
        receiverSignUpCanceled
      }
    }
  }
`);

const UpdateCompany = graphql(/* GraphQL */ `
  mutation updateCompanySettings(
    $companyId: String!
    $company: UpdateCompanyDto!
  ) {
    updateCompany(companyId: $companyId, company: $company) {
      visualSettings {
        showTutor
        showEndOfRegistrationForSeminar
        showDescription
        showSeminarNumber
        showPrice
        showDate
        showLocation
        showSignalLight
        showImages
        backToDashboardActive
        backToMainActive
        primaryColor
        primaryTextColor
      }
      active
      domain
      functionalSettings {
        errorTextWrongPassword
        infoText
        passwordActive
        priceCalculation
        registrationActive
        searchActive
        seminarsOrderBy
        showPastSeminars
        waitlistActive
        disableUserAccounts
        showBookedOutSeminars
        cancelSignUpActive
      }
    }
  }
`);

interface FormInputs {
  visualSettings: VisualSettings;
  active: boolean;
  functionalSettings: FunctionalSettings;
  domain: string;
}

export const GeneralSettingsPage: React.FC<GeneralSettingsPageProps> = ({
  companyId,
}) => {
  // TODO: Error handling
  const [{ data, fetching }] = useQuery({
    query: CompanySettingsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [, updateCompany] = useMutation(UpdateCompany);

  const { control, formState, handleSubmit, reset } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      functionalSettings: { infoText: '' },
      visualSettings: {
        primaryColor: 'rgba(237, 126, 50, 1)',
      },
    },
  });

  const onFormSubmit = (values: FormInputs) => {
    updateCompany({
      companyId,
      company: values,
    }).then((r) => {
      const withoutTypename =
        r.data?.updateCompany && cloneAndRemoveTypename(r.data?.updateCompany);
      // TODO: Check for errors and indicate success/failure to UI
      if (r.data?.updateCompany) reset(withoutTypename);
    });
  };

  useEffect(() => {
    if (!fetching) {
      const withoutTypename =
        data?.company && cloneAndRemoveTypename(data.company);

      if (withoutTypename) {
        reset({
          active: withoutTypename.active,
          domain: withoutTypename.domain,
          visualSettings: withoutTypename.visualSettings,
          functionalSettings: withoutTypename.functionalSettings,
        });
      }
    }
  }, [fetching, JSON.stringify(data?.company)]);

  const { isValid, isDirty } = formState;

  return (
    <>
      <PageTitle title={'Allgemeine Einstellungen'} />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Darstellung"
          description="Beeinflussen Sie die Darstellung ihrer Seminare auf der öffentlichen Webseite."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <CheckboxStateWrapper
              name={'visualSettings.showTutor'}
              control={control}
              label={'Referenten anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showEndOfRegistrationForSeminar'}
              control={control}
              label={'Anmeldeschluss anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showDescription'}
              control={control}
              label={'Beschreibung anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showSeminarNumber'}
              control={control}
              label={'Seminarnummer anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showPrice'}
              control={control}
              label={'Preis anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showDate'}
              control={control}
              label={'Zeit anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showLocation'}
              control={control}
              label={'Ort anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showImages'}
              control={control}
              label={'Bilder anzeigen'}
            />
            {/* <CheckboxStateWrapper
              name={'visualSettings.backToDashboardActive'}
              control={control}
              label={'"Zurück zur Übersicht" anzeigen'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.backToMainActive'}
              control={control}
              label={'"Zurück zur Hauptseite" anzeigen'}
            /> */}

            <FieldWrapper>
              <Label>Primäre Farbe</Label>
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <ColorPicker
                    {...rest}
                    onChange={(event: ColorPickerChangeEvent) =>
                      onChange(event.value)
                    }
                    view={'gradient'}
                  />
                )}
                name={'visualSettings.primaryColor'}
                control={control}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Label>Primäre Textfarbe</Label>
              <Controller
                render={({ field: { onChange, ...rest } }) => (
                  <ColorPicker
                    {...rest}
                    onChange={(event: ColorPickerChangeEvent) =>
                      onChange(event.value)
                    }
                    view={'gradient'}
                  />
                )}
                name={'visualSettings.primaryTextColor'}
                control={control}
              />
            </FieldWrapper>
          </div>
        </Fieldset>

        <Fieldset
          legend="Funktionalität"
          description="Steuern Sie das Verhalten und die aktivierten Funktionen für die öffentliche Webseite."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <CheckboxStateWrapper
              name={'active'}
              control={control}
              label={'Öffentliche Webseite komplett deaktivieren'}
              inverted
            />
            <CheckboxStateWrapper
              name={'functionalSettings.registrationActive'}
              control={control}
              label={'Teilnehmer können sich an Seminaren anmelden'}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.cancelSignUpActive'}
              control={control}
              label={'Teilnehmer können sich von Seminaren abmelden'}
              hint={
                'Nur möglich, wenn in den E-Mail-Einstellungen ein Empfänger für Abmeldungen gesetzt wurde'
              }
              disabled={!data?.company?.mailSettings?.receiverSignUpCanceled}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.waitlistActive'}
              control={control}
              label={'Warteliste aktiviert'}
            />
            <CheckboxStateWrapper
              name={'visualSettings.showSignalLight'}
              control={control}
              label={'Ampel aktiviert'}
              hint={
                'Sofern weniger als 90% belegt sind und mindestens zwei Plätze frei sind, wird eine grüne Ampel angezeigt.'
              }
            />
            <DropdownStateWrapper
              options={[
                { text: 'Seminarbeginn', value: SeminarOrderBy.SeminarStart },
                { text: 'Seminarende', value: SeminarOrderBy.SeminarEnd },
                { text: 'Seminarnummer', value: SeminarOrderBy.SeminarNumber },
                { text: 'Beschreibung', value: SeminarOrderBy.Description },
              ]}
              name={'functionalSettings.seminarsOrderBy'}
              control={control}
              label={'Seminar Sortierung'}
            />
            {/* <DropdownStateWrapper
              options={[
                {
                  text: 'Preis Auswahl',
                  value: PriceCalculation.PriceSelection,
                },
                {
                  text: 'Gültige Kombinationen',
                  value: PriceCalculation.Combination,
                },
              ]}
              name={'functionalSettings.priceCalculation'}
              control={control}
              label={'Preisberechnung'}
            /> */}
            <CheckboxStateWrapper
              name={'functionalSettings.searchActive'}
              control={control}
              label={'Suche aktiviert'}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.disableUserAccounts'}
              control={control}
              label={'Benutzerkonten komplett deaktivieren (kein Login-Button)'}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.showPastSeminars'}
              control={control}
              label={'Vergangene Seminare auflisten'}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.showBookedOutSeminars'}
              control={control}
              label={'Ausgebuchte Seminare auflisten'}
            />
            <CheckboxStateWrapper
              name={'functionalSettings.passwordActive'}
              control={control}
              label={'Komplette Webseite nur für angemeldete Nutzer erreichbar'}
            />
            <InputStateWrapper
              name={'domain'}
              control={control}
              label={'Domain'}
            />
            <InputStateWrapper
              name={'functionalSettings.errorTextWrongPassword'}
              control={control}
              label={'Fehlermeldung bei falschem Seminar-Passwort'}
            />
            <FieldWrapper>
              <Label>Warnhinweis / Infotext</Label>
              <Controller
                render={({ field: { onChange, value, ...rest } }) => (
                  <Editor
                    {...rest}
                    value={value ?? ''}
                    onChange={(event: EditorChangeEvent) => {
                      onChange(event?.html);
                    }}
                    tools={[
                      [Bold, Italic, Underline, Strikethrough],
                      FormatBlock,
                      [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                      [Indent, Outdent, OrderedList, UnorderedList],
                      [Link, Unlink, InsertImage],
                    ]}
                    contentStyle={{
                      height: 200,
                    }}
                  />
                )}
                name={'functionalSettings.infoText'}
                control={control}
              />
            </FieldWrapper>
          </div>
        </Fieldset>

        <Fieldset
          legend="BTS Sync Secret"
          description="Dieses Secret wird benötigt um die Webwelt mit der lokalen BTS-Software zu synchronisieren."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <Input
              value={data?.company?.secret}
              id="secret"
              label={'Secret'}
              className="cursor-not-allowed"
              readOnly
            />
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
