export const getFormattedSeminarDate = (
  startDate: string,
  endDate: string,
  dateText?: string | null,
) => {
  if (dateText) {
    return dateText;
  }

  if (!startDate && !endDate) {
    return undefined;
  }

  const startDateObj = startDate ? new Date(startDate) : undefined;
  const endDateObj = endDate ? new Date(endDate) : undefined;
  const formattedStartDateString = `${startDateObj?.toLocaleDateString(
    'de-DE',
    {
      dateStyle: 'long',
    },
  )}`;
  const formattedStartTimeString = `${startDateObj?.toLocaleTimeString(
    'de-DE',
    {
      timeStyle: 'short',
    },
  )}`;
  const formattedEndDateString = `${endDateObj?.toLocaleDateString('de-DE', {
    dateStyle: 'long',
  })}`;
  const formattedEndTimeString = `${endDateObj?.toLocaleTimeString('de-DE', {
    timeStyle: 'short',
  })}`;
  if (
    formattedStartDateString === formattedEndDateString &&
    formattedStartDateString
  ) {
    return `${formattedStartDateString} (${formattedStartTimeString} - ${formattedEndTimeString} Uhr)`;
  }
  return `${startDate ? `${formattedStartDateString} (${formattedStartTimeString} Uhr)` : ''}${endDate ? ` - ${formattedEndDateString} (${formattedEndTimeString} Uhr)` : ''}`;
};

export const getFormattedDate = (date: string): string => {
  const dateObj = date ? new Date(date) : undefined;

  if (dateObj) {
    const formattedDateString = `${dateObj?.toLocaleDateString('de-DE', {
      dateStyle: 'long',
    })}`;
    const formattedTimeString = `${dateObj?.toLocaleTimeString('de-DE', {
      timeStyle: 'short',
    })}`;

    return `${formattedDateString} (${formattedTimeString} Uhr)`;
  }

  return '';
};

export const cloneAndRemoveTypename = <T>(obj: T): T => {
  return JSON.parse(
    JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)),
  );
};

export const truncateStringAtWhitespace = (
  str: string,
  maxLen: number,
  separator = ' ',
) => {
  if (str.length <= maxLen) return str;
  return str.substring(0, str.lastIndexOf(separator, maxLen)) + '...';
};
