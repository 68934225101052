import { graphql } from '../../gql';

const CompaniesQuery = graphql(/* GraphQL */ `
  query getCompanies {
    companiesForSessionUser {
      user {
        firstName
        lastName
        title
        email
        isSuperUser
        allowSevenDaysReminder
        allowOneDayReminder
      }
      companiesForUser {
        id
        privileges
        name
        domain
      }
    }
  }
`);

export { CompaniesQuery };
