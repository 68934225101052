import { graphql } from '../gql/index.ts';
import { useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { getFormattedSeminarDate } from './util.ts';
import { ErrorPage } from './ErrorPage.tsx';
import { PublicProfileSidebar } from './PublicProfileSidebar.tsx';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { HeroIcon } from '../shared/HeroIcon.tsx';

const MyLecturerSeminarsQuery = graphql(/* GraphQL */ `
  query getMyLecturerSeminars($companyId: String!) {
    myLecturerSeminars(companyId: $companyId) {
      seminars {
        id
        title
        subtitle
        seminarNumber
        dateText
        description
        plainDescription
        locationText
        startDate
        endDate
        lecturers {
          name
        }
        categories {
          name
        }
      }
      count
    }
  }
`);

interface MyLecturerSeminarListProps {
  companyId: string;
}

export const MyLecturerSeminarList: React.FC<MyLecturerSeminarListProps> = ({
  companyId,
}) => {
  const [myLecturerSeminarsQueryResult] = useQuery({
    query: MyLecturerSeminarsQuery,
    variables: {
      companyId,
    },
    pause: companyId === undefined || companyId === null,
  });
  const { data } = myLecturerSeminarsQueryResult;
  const { myLecturerSeminars } = data || {};
  const [publicCompany] = usePublicCompany();
  const { userInfo } = useUserInfo();

  const showSidebar =
    publicCompany?.functionalSettings?.searchActive ||
    Boolean(publicCompany?.sites?.length) ||
    Boolean(publicCompany?.categories?.length);

  return (
    myLecturerSeminarsQueryResult.data && (
      <>
        <PublicProfileSidebar />
        <div
          className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
        >
          <div className={'flex justify-between'}>
            <h2>
              Meine Dozententätigkeit
              {`${myLecturerSeminars?.count ? ` (${myLecturerSeminars?.count})` : ''}`}
            </h2>
            {window.location.host &&
              userInfo?.data?.companiesForSessionUser.user.lecturerId && (
                <a
                  href={`webcal://${window.location.host}/api/calendar/seminars-for-lecturer/ics?lecturerId=${userInfo?.data?.companiesForSessionUser.user.lecturerId}`}
                  className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md flex justify-center"
                  target="_blank"
                  download=""
                >
                  <HeroIcon name="Calendar" className="inline-block h-5 w-5" />
                  In Kalender importieren (.ics)
                </a>
              )}
          </div>
          {myLecturerSeminars?.count ? (
            myLecturerSeminars.seminars?.map((seminar) => {
              return (
                <SeminarCard
                  key={seminar?.id}
                  instructors={
                    seminar?.lecturers
                      ?.map((lecturer) => lecturer.name)
                      .filter(Boolean) as string[]
                  }
                  id={seminar?.seminarNumber}
                  title={seminar?.title}
                  time={getFormattedSeminarDate(
                    seminar.startDate,
                    seminar.endDate,
                    seminar.dateText,
                  )}
                  description={seminar?.plainDescription}
                  subtitle={seminar?.subtitle}
                  location={seminar?.locationText}
                  category={
                    seminar?.categories?.find((c) => Boolean(c.name.trim()))
                      ?.name
                  }
                  titleLink={'/seminar/' + seminar?.id}
                />
              );
            })
          ) : (
            <ErrorPage text="Sie geben momentan keine Seminare" />
          )}
        </div>
      </>
    )
  );
};
