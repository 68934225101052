import { graphql } from '../../gql/index.ts';
import { Exact, GetUserInfoQuery } from '../../gql/graphql';
import { createContext, useEffect, useState } from 'react';
import { UseQueryExecute, UseQueryState, useQuery } from 'urql';

export type UserInfo = GetUserInfoQuery['companiesForSessionUser'];

interface PublicUserContextProps {
  children: React.ReactNode;
}

export type PublicUserContextType = {
  userInfo:
    | UseQueryState<
        GetUserInfoQuery,
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined;
  refetchUserInfo: UseQueryExecute;
};

export const PublicUserInfoContext = createContext<PublicUserContextType>({
  userInfo: undefined,
  refetchUserInfo: () => {},
});

const UserCompaniesQuery = graphql(/* GraphQL */ `
  query getUserInfo {
    companiesForSessionUser {
      user {
        title
        firstName
        lastName
        id
        email
        allowSevenDaysReminder
        allowOneDayReminder
        allowOneDayReminderForLecturer
        allowSevenDaysReminderForLecturer
        lecturerId
        vita
        jobTitle
        pathToProfileImage
      }
      companiesForUser {
        id
        privileges
        name
      }
    }
  }
`);

export const PublicUserContextProvider: React.FC<PublicUserContextProps> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<
    | UseQueryState<
        GetUserInfoQuery,
        Exact<{
          [key: string]: never;
        }>
      >
    | undefined
  >(undefined);

  const [userCompaniesQueryResult, reExecuteQuery] = useQuery({
    query: UserCompaniesQuery,
  });

  useEffect(() => {
    if (userCompaniesQueryResult) {
      setUserInfo(userCompaniesQueryResult);
    }
  }, [userCompaniesQueryResult]);

  return (
    <PublicUserInfoContext.Provider
      value={{ userInfo, refetchUserInfo: reExecuteQuery }}
    >
      {children}
    </PublicUserInfoContext.Provider>
  );
};
