import { HeroIcon } from '../shared/HeroIcon.tsx';
import { Link } from 'wouter';
import { ModulePrivileges } from '../gql/graphql';

export interface IAdminMenuItem {
  href?: string;
  icon?: string;
  text: string;
  expanded?: boolean;
  items?: Array<IAdminMenuItem>;
  selected?: boolean;
  privilege?: ModulePrivileges;
  disabled?: boolean;
  newTab?: boolean;
}

interface AdminMenuItemProps {
  item: IAdminMenuItem;
  isActive?: boolean;
}

export const AdminMenuItem: React.FC<AdminMenuItemProps> = ({
  item,
  isActive,
}) => {
  const commonTextClasses = 'flex items-center justify-between gap-2';
  const content = (
    <>
      {item.icon && (
        <HeroIcon name={item.icon} className="inline-block h-5 w-5" />
      )}
      <span className="inline-block">{item.text}</span>
    </>
  );
  if (item.href) {
    if (item.newTab) {
      return (
        <a
          className={`${commonTextClasses} font-normal text-zinc-800`}
          href={item.href}
          target="_blank"
        >
          {content}
        </a>
      );
    } else {
      return (
        <Link
          className={`${commonTextClasses} text-zinc-800 ${
            isActive ? 'font-semibold' : 'font-normal'
          }`}
          href={item.href}
          key={item.text}
        >
          {content}
        </Link>
      );
    }
  } else {
    return (
      <div className={`${commonTextClasses} font-semibold text-zinc-500`}>
        {content}
      </div>
    );
  }
};
