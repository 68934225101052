import { createContext, useState, useLayoutEffect } from 'react';
import { useLocation } from 'wouter';
import { useQuery } from 'urql';
import { useDeepCompareEffect } from 'react-use';
import { GetCompaniesQuery } from '../../gql/graphql.ts';
import { CompaniesQuery } from '../queries/CompaniesQuery.ts';
import { usePublicCompany } from '../../public/hooks/usePublicCompany.ts';
import {
  menu,
  getPrivilegedCurrentMenu,
  getFirstAllowedMenuItem,
  findInMenuTree,
} from '../menu.ts';
import { IAdminMenuItem } from '../AdminMenuItem.tsx';
import { canAccessAdminPanel } from '../../public/permissions.ts';

export type CompaniesData = GetCompaniesQuery['companiesForSessionUser'];

interface UserCompaniesAndPrivilegeProviderProps {
  children: React.ReactNode;
}

export type CompaniesForUserContextType = {
  companiesAndUser: CompaniesData | undefined;
  menu: IAdminMenuItem[];
  setMenu: React.Dispatch<React.SetStateAction<IAdminMenuItem[]>>;
};

/**
 * This Provider watches all location changes in the Admin panel and checks whether the user has the right privilege to access a certain page
 * It also stores the current menu items and their state after applying the proper privileges and the currently selected item.
 * It also stores the currently used publicCompany and its information, as well as all accessible other companies and their domains (for the company switcher)
 */
export const UserCompaniesAndPrivilegeProvider: React.FC<
  UserCompaniesAndPrivilegeProviderProps
> = ({ children }) => {
  const [location, setLocation] = useLocation();
  const [publicCompany] = usePublicCompany();
  const [companies, setCompanies] = useState<CompaniesData>();

  // The menu is a static data structure, but we need to apply privileges to it (so the "unallowed" items are disabled) and mark the currently active menu item
  const [privilegedCurrentMenu, setPrivilegedCurrentMenu] =
    useState<IAdminMenuItem[]>(menu);

  const [companiesQueryResult] = useQuery({
    query: CompaniesQuery,
  });

  useDeepCompareEffect(() => {
    if (
      companiesQueryResult.error &&
      companiesQueryResult.error.graphQLErrors.some(
        (gqlError) => gqlError.message === 'Forbidden resource',
      )
    ) {
      window.location.href = `/api/auth/login?successRef=${encodeURIComponent(window.location.href)}`;
    } else if (companiesQueryResult.data) {
      const currentCompany =
        companiesQueryResult.data.companiesForSessionUser.companiesForUser.find(
          (companyForUser) => companyForUser.id === publicCompany?.id,
        );
      if (currentCompany?.privileges?.length) {
        // Redirect to public frontend if the given user has none of the admin-role privileges
        const isAdminForCompany = canAccessAdminPanel(
          currentCompany?.privileges,
        );
        if (!isAdminForCompany) {
          window.location.href = `/`;
        }
      }
      setCompanies(companiesQueryResult.data.companiesForSessionUser);
    }
  }, [companiesQueryResult]);

  useLayoutEffect(() => {
    if (companies && publicCompany) {
      // Find the currently used company among all companies that the user has access to
      const currentCompany = companies.companiesForUser.find(
        (companyForUser) => companyForUser.id === publicCompany?.id,
      );

      let updatedMenu: IAdminMenuItem[] = [];

      // User has privilege, he may see the page but we need to prepare the menu to look correct
      if (currentCompany?.privileges) {
        // Update Menu to show currently active item and disable unallowed items
        updatedMenu = getPrivilegedCurrentMenu(
          currentCompany?.privileges,
          location,
        );
        setPrivilegedCurrentMenu(updatedMenu);
      }

      const wantedMenuItem = findInMenuTree(
        (item) => item.href === location,
        updatedMenu,
      );

      // Do something if user has no privilege (e.g. go to error page)
      if (wantedMenuItem?.disabled) {
        const allowedAlternative = getFirstAllowedMenuItem(
          privilegedCurrentMenu,
        );

        if (allowedAlternative?.href) setLocation(allowedAlternative.href);
      }
    }
  }, [location, companies, publicCompany, setLocation]);

  return (
    <CompaniesContext.Provider
      value={{
        companiesAndUser: companies,
        menu: privilegedCurrentMenu,
        setMenu: setPrivilegedCurrentMenu,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export const CompaniesContext = createContext<CompaniesForUserContextType>({
  companiesAndUser: undefined,
  menu,
  setMenu: () => {},
});
