import {
  MonthView,
  Scheduler,
  SchedulerForm,
  SchedulerFormEditor,
  SchedulerItem,
} from '@progress/kendo-react-scheduler';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { PublicProfileSidebar } from './PublicProfileSidebar.tsx';
import '@progress/kendo-date-math/tz/Europe/Berlin';

import { useEffect, useState } from 'react';
import { graphql } from '../gql';
import { useMutation, useQuery } from 'urql';
import { cloneAndRemoveTypename } from './util.ts';
import { Dialog } from '@progress/kendo-react-dialogs';

interface MyAvailabilityProps {
  companyId: string;
}

const OwnLecturerAvailabilityEventsQuery = graphql(/* GraphQL */ `
  query ownLecturerAvailabilityEvents($companyId: String!) {
    ownLecturerAvailabilityEvents(companyId: $companyId) {
      id
      begin
      end
    }
  }
`);

const CalendarAvailabilityMutation = graphql(/* GraphQL */ `
  mutation saveAvailability(
    $changedEvents: ChangedAvailabilityEventsInput!
    $companyId: String!
  ) {
    saveAvailability(changedEvents: $changedEvents, companyId: $companyId) {
      added {
        id
        start
        end
      }
    }
  }
`);

interface CalendarEvent {
  id: string;
  start: Date;
  end: Date;
}

export const MyAvailability: React.FC<MyAvailabilityProps> = ({
  companyId,
}) => {
  const [publicCompany] = usePublicCompany();
  const [data, setData] = useState<CalendarEvent[]>([]);

  const [, updateCalendarAvailability] = useMutation(
    CalendarAvailabilityMutation,
  );

  const [{ data: dataServer }] = useQuery({
    query: OwnLecturerAvailabilityEventsQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  useEffect(() => {
    setData(
      cloneAndRemoveTypename(
        dataServer?.ownLecturerAvailabilityEvents ?? [],
      )?.map((e) => ({
        id: e.id,
        start: new Date(e.begin),
        end: new Date(e.end),
      })),
    );
  }, [JSON.stringify(dataServer)]);

  const showSidebar =
    publicCompany?.functionalSettings?.searchActive ||
    Boolean(publicCompany?.sites?.length) ||
    Boolean(publicCompany?.categories?.length);

  const handleChange = (newData: {
    updated?: Array<CalendarEvent>;
    deleted?: Array<CalendarEvent>;
    created?: Array<CalendarEvent>;
  }) => {
    if (
      newData?.updated?.length ||
      newData?.deleted?.length ||
      newData?.created?.length
    ) {
      setData((oldState) => {
        let newState = structuredClone(oldState);
        if (newData?.deleted?.length) {
          newState = newState
            .map((item) =>
              newData?.deleted?.some((current) => current.id === item.id)
                ? null
                : item,
            )
            .filter(Boolean) as CalendarEvent[];
        }
        if (newData?.updated?.length) {
          newState = newState.map(
            (item) =>
              newData?.updated?.find((current) => current.id === item.id) ||
              item,
          );
        }
        return newState;
      });

      updateCalendarAvailability({
        companyId: companyId,
        changedEvents: {
          updated:
            newData.updated?.map((e) => ({
              id: e.id,
              end: e.end,
              start: e.start,
            })) ?? [],
          deleted:
            newData.deleted?.map((e) => ({
              id: e.id,
              end: e.end,
              start: e.start,
            })) ?? [],
          added:
            newData.created?.map((e) => ({
              id: e.id,
              end: e.end,
              start: e.start,
            })) ?? [],
        },
      }).then(({ data }) => {
        const dataCloned = cloneAndRemoveTypename(data?.saveAvailability);
        setData((oldState) => {
          return [
            ...oldState,
            ...((dataCloned?.added.map((e) => ({
              ...e,
              start: new Date(e.start),
              end: new Date(e.end),
            })) ?? []) as CalendarEvent[]),
          ];
        });
      });
    }
  };

  return (
    <>
      <PublicProfileSidebar />
      <div
        className={`${showSidebar ? 'md:col-span-9 md:col-start-4' : 'md:col-span-12'} col-span-1 flex flex-col gap-4`}
      >
        <h2>Meine Verfügbarkeit</h2>
        <div className="relative flex flex-col rounded-md border border-gray-200 bg-white p-6 md:p-8">
          <Scheduler
            editable={true}
            onDateChange={(event) => {
              console.log(event.value);
            }}
            onDataChange={handleChange}
            timezone="Europe/Berlin"
            data={data}
            height={'100%'}
            footer={() => <></>}
            form={(props) => (
              <SchedulerForm
                {...props}
                dialog={(props) => (
                  <Dialog
                    {...props}
                    title={'Verfügbarkeit'}
                    appendTo={document.getElementById('custom-dialog-root')}
                  />
                )}
                editor={(props) => (
                  <SchedulerFormEditor
                    {...props}
                    titleEditor={() => <></>}
                    titleLabel={() => <></>}
                    descriptionEditor={() => <></>}
                    descriptionLabel={() => <></>}
                    startTimezoneEditor={() => <></>}
                    endTimezoneEditor={() => <></>}
                    startTimezoneLabel={() => <></>}
                    startTimezoneCheckedEditor={() => <></>}
                    startTimezoneCheckedLabel={() => <></>}
                    recurrenceEditor={() => <></>}
                    className="[&_.k-form-field-wrap]:!flex [&_.k-form-field-wrap]:gap-2"
                  />
                )}
              />
            )}
            item={(props) => (
              <SchedulerItem {...props} onClick={(e) => console.log(e)} />
            )}
            style={
              {
                '--kendo-font-size': '0.875em',
              } as React.CSSProperties
            }
            className="[&_.k-scheduler-body]:h-[calc(100%-50px)] [&_.k-slot-cell:not(.k-other-month):not(.k-selected)]:bg-white"
          >
            <MonthView
              selectedDateFormat="{0:D}"
              selectedShortDateFormat="{0:D}"
            />
          </Scheduler>
        </div>
      </div>
    </>
  );
};
