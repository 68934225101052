import { Link, useLocation } from 'wouter';
import { useUserInfo } from './hooks/useUserInfo';
import { usePublicCompany } from './hooks/usePublicCompany';
import { ModulePrivileges } from '../gql/graphql';
import { HeroIcon } from '../shared/HeroIcon';

export const PublicProfileSidebar: React.FC = () => {
  const [location] = useLocation();
  const { userInfo } = useUserInfo();
  const [publicCompany] = usePublicCompany();

  const isLecturerForCompany = Boolean(
    userInfo?.data?.companiesForSessionUser?.companiesForUser
      .find((company) => company.id === publicCompany?.id)
      ?.privileges.includes(ModulePrivileges.Lecturer),
  );

  const hasNotificationSettings =
    publicCompany?.mailSettings?.sendReminderSevenDays ||
    publicCompany?.mailSettings?.sendReminderOneDay ||
    publicCompany?.mailSettings?.sendReminderOneDayForLecturer ||
    publicCompany?.mailSettings?.sendReminderSevenDaysForLecturer;

  const items: Array<{
    title: string;
    href: string;
    icon?: string;
  }> = [
    {
      title: 'Seminarteilnahmen',
      href: '/profile/myseminars',
      icon: 'PresentationChartBar',
    },
    isLecturerForCompany && {
      title: 'Veranstaltungen',
      href: '/profile/mylecturerseminars',
      icon: 'Briefcase',
    },
    // isLecturerForCompany && {
    //   title: 'Verfügbarkeit',
    //   href: '/profile/availability',
    //   icon: 'CalendarDays',
    // },
    isLecturerForCompany && {
      title: 'Dozentenprofil',
      href: '/profile/lecturer',
      icon: 'AcademicCap',
    },
    {
      title: 'Stammdaten',
      href: '/profile/data',
      icon: 'UserCircle',
    },
    hasNotificationSettings && {
      title: 'Benachrichtigungen',
      href: '/profile/notifications',
      icon: 'BellAlert',
    },
  ].filter(Boolean) as Array<{ title: string; href: string }>;

  return (
    <aside className="col-span-1 flex flex-col gap-4 md:col-span-3">
      <h2>Profil</h2>
      <ul className="flex flex-col divide-y rounded border border-gray-200 bg-white">
        {items.map((item, i) => {
          const isActive = location === item.href;
          return (
            <li
              key={item.href}
              className={`text-black ${i == 0 ? 'rounded-t' : ''} ${i == items.length - 1 ? 'rounded-b' : ''} ${isActive ? 'bg-bts-theme-primary' : 'hover:bg-gray-200'}`}
            >
              <Link
                href={item.href}
                className={
                  isActive
                    ? 'bg-bts-theme-primary text-white'
                    : '' + ' h-full w-full'
                }
              >
                <span className="flex h-full w-full items-center gap-2 px-6 py-4">
                  {item.icon && (
                    <HeroIcon
                      name={item.icon}
                      className="inline-block h-5 w-5 shrink-0 text-black/40"
                    />
                  )}
                  {item.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
