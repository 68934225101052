import { Helmet } from 'react-helmet-async';
import { graphql } from '../gql';
import React, { useEffect } from 'react';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { InputStateWrapper } from '../admin/form-components/InputStateWrapper.tsx';
import { useForm } from 'react-hook-form';
import { DropdownStateWrapper } from '../admin/form-components/DropdownStateWrapper.tsx';
import { useMutation, useQuery } from 'urql';
import {
  PersonTitle,
  SignUpCustomConditionalFieldOption,
  SignUpCustomFieldType,
  SignUpCustomFieldValidator,
  SignUpResult,
} from '../gql/graphql.ts';
import { DatePickerStateWrapper } from '../admin/form-components/DatePickerStateWrapper.tsx';
import { CheckboxStateWrapper } from '../admin/form-components/CheckboxStateWrapper.tsx';
import { Label } from '@progress/kendo-react-labels';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { Button } from '@progress/kendo-react-buttons';
import { TextareaStateWrapper } from '../admin/form-components/TextareaStateWrapper.tsx';
import { Alert } from '../shared/Alert.tsx';
import { PublicDetailsSidebar } from './PublicDetailsSidebar.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { Loader } from '@progress/kendo-react-indicators';
import { FieldWrapper } from '@progress/kendo-react-form';
import { BackButton } from '../shared/BackButton.tsx';

const PublicSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSeminarForSignUp($seminarId: String!, $companyId: String!) {
    publicSeminar(seminarId: $seminarId, companyId: $companyId) {
      id
      title
      subtitle
      seminarNumber
      startDate
      endDate
      dateText
      description
      locationText
      price
      priceText
      price2
      priceText2
      price3
      priceText3
      price4
      priceText4
      needsPassword
      isBookedOut
      isAlmostBookedOut
      lecturers {
        name
      }
      categories {
        name
      }
    }
  }
`);

const PublicSignUpFormElements = graphql(/* GraphQL */ `
  query getPublicSignUpFormElements($companyId: String!) {
    publicCompanySignUpCustomFields(companyId: $companyId) {
      id
      options {
        value
        displayValue
      }
      visibleIfValue
      visibleIfField
      validator
      type
      systemField
      required
      name
    }
  }
`);

const PublicCompanySignUpFormGroupSelectElements = graphql(/* GraphQL */ `
  query getPublicCompanySignUpFormGroupSelectElements($companyId: String!) {
    publicCompanySignUpCustomConditionalFieldGroup(companyId: $companyId) {
      fields {
        displayName
        name
        child {
          displayName
          name
          child {
            displayName
            name
            child {
              displayName
              name
            }
          }
        }
      }
      options {
        displayName
        value
        children {
          displayName
          value
          children {
            displayName
            value
            children {
              displayName
              value
            }
          }
        }
      }
    }
  }
`);

export const SignUpMutation = graphql(/* GraphQL */ `
  mutation signUp($signUp: CreateSignUpDto!) {
    publicSignUp(signUp: $signUp) {
      result
    }
  }
`);

interface Props {
  seminarId: string;
  companyId: string;
}

interface SignUpForm {
  title: PersonTitle;
  firstName: string;
  lastName: string;
  email: string;
  fields: {
    [key: string]: string | boolean | Date;
  };
  groupSelect: {
    [key: string]: SignUpCustomConditionalFieldOption;
  };
  password: string;
}

export const PublicSignUpPage: React.FC<Props> = ({ seminarId, companyId }) => {
  const [{ data }] = useQuery({
    query: PublicSeminarQuery,
    variables: {
      companyId,
      seminarId,
    },
    pause:
      companyId === undefined ||
      companyId === null ||
      seminarId === undefined ||
      seminarId === null,
  });

  const [{ data: dataSignUpFormElements, fetching }] = useQuery({
    query: PublicSignUpFormElements,
    variables: {
      companyId,
    },
    pause: companyId === undefined || companyId === null,
  });

  const [{ data: dataSignUpFormGroupSelects }] = useQuery({
    query: PublicCompanySignUpFormGroupSelectElements,
    variables: {
      companyId,
    },
    pause: companyId === undefined || companyId === null,
  });

  const [signUpResult, signUp] = useMutation(SignUpMutation);

  const { userInfo } = useUserInfo();

  const { control, formState, handleSubmit, watch, reset } =
    useForm<SignUpForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: {
        fields: {},
        groupSelect: {},
      },
    });

  useEffect(() => {
    if (!fetching && dataSignUpFormElements?.publicCompanySignUpCustomFields) {
      reset(
        {
          title: userInfo?.data?.companiesForSessionUser?.user?.title as
            | PersonTitle
            | undefined,
          email: userInfo?.data?.companiesForSessionUser?.user?.email,
          firstName: userInfo?.data?.companiesForSessionUser?.user
            ?.firstName as string | undefined,
          lastName: userInfo?.data?.companiesForSessionUser?.user?.lastName as
            | string
            | undefined,
          fields: dataSignUpFormElements.publicCompanySignUpCustomFields
            .filter((ele) => ele.type === SignUpCustomFieldType.Check)
            .reduce((a, ele) => ({ ...a, [ele.id]: false }), {}),
          groupSelect: {},
        },
        { keepDefaultValues: true },
      );
    }
  }, [fetching]);

  const onSubmit = (values: SignUpForm) => {
    return signUp({
      signUp: {
        companyId: companyId,
        seminarId: seminarId,
        email: values.email.trim(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        title: values.title,
        fields: Object.entries(values.fields).map(([key, value]) => ({
          key: key,
          value: value?.toString().trim() ?? '',
        })),
        groupSelectFields: Object.entries(values.groupSelect).map(
          ([key, value]) => ({
            key: key,
            value: value.value,
          }),
        ),
        password: values.password,
      },
    });
  };
  const { publicSeminar: seminar } = data || {};

  const allFields = watch();
  const { isValid, isDirty, isSubmitting } = formState;

  const getCustomConditionalFields = () => {
    if (!allFields.groupSelect) {
      return;
    }
    let parent = null;
    let currentField =
      dataSignUpFormGroupSelects?.publicCompanySignUpCustomConditionalFieldGroup
        .fields;
    let currentOptions =
      dataSignUpFormGroupSelects?.publicCompanySignUpCustomConditionalFieldGroup
        .options;
    const result = [];
    while (currentField?.name && currentOptions) {
      if (parent && !allFields?.groupSelect[parent.name]) {
        break;
      }
      result.push(
        <DropdownStateWrapper
          name={`groupSelect.${currentField.name}`}
          key={currentField.name}
          control={control}
          wrapperClassName={'col-span-2'}
          label={currentField.displayName}
          className={`!w-auto`}
          rules={{ required: true }}
          required={true}
          options={currentOptions.map((opt) => ({
            text: opt.displayName,
            value: opt,
          }))}
        />,
      );
      parent = currentField;
      currentField = currentField.child;
      currentOptions = allFields.groupSelect[parent.name]?.children;
    }
    return result;
  };

  const [publicCompany] = usePublicCompany();

  return (
    <>
      <Helmet>
        <title>
          {seminar?.title || 'Seminar'} - {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>

      <div className="col-span-1 flex flex-col gap-4 md:col-span-9">
        <BackButton />
        {data?.publicSeminar?.title && (
          <h2 className="col-span-2">{data?.publicSeminar.title}</h2>
        )}
        {data?.publicSeminar.subtitle && (
          <h3 className="text-bts-theme-text/70 col-span-2 text-lg font-normal">
            {data?.publicSeminar.subtitle}
          </h3>
        )}
        {!userInfo?.data?.companiesForSessionUser?.user &&
          publicCompany?.functionalSettings.disableUserAccounts === false &&
          !signUpResult.data && (
            <Alert type="warning" icon="InformationCircle">
              Wenn Sie bereits einen Account besitzen, loggen Sie sich bitte
              ein, um ihre Nutzerdaten automatisch in die Anmeldung zu
              übernehmen.
            </Alert>
          )}
        {signUpResult?.data?.publicSignUp.result === SignUpResult.Success && (
          <div className="flex gap-4 rounded-md border border-gray-200 bg-white p-6 md:p-8">
            <HeroIcon
              name="CheckCircle"
              className="mt-1 h-7 w-7 text-green-600"
            />
            <div className="flex flex-col gap-2">
              <h3 className="text-green-600">Anmeldung war erfolgreich</h3>
              <p>
                Ihre Anmeldung wurde registriert. Der Veranstalter wird sich mit
                Ihnen in Verbindung setzen, und Ihnen weitere Informationen
                zukommen lassen.
              </p>
            </div>
          </div>
        )}
        {signUpResult?.data?.publicSignUp.result === SignUpResult.ErrorFull && (
          <div className="flex gap-4 rounded-md border border-gray-200 bg-white p-6 md:p-8">
            <HeroIcon name="XCircle" className="mt-1 h-7 w-7 text-red-600" />
            <div className="flex flex-col gap-2">
              <h3 className="text-red-600">Anmeldung war nicht erfolgreich</h3>
              <p>
                Ihre Anmeldung konnte nicht durcheführt werden. Die
                Veranstaltung ist leider bereits ausgebucht.
              </p>
            </div>
          </div>
        )}
        {signUpResult?.data?.publicSignUp.result === SignUpResult.Waitlist && (
          <div className="flex gap-4 rounded-md border border-gray-200 bg-white p-6 md:p-8">
            <HeroIcon name="CheckCircle" className="mt-1 h-7 w-7" />
            <div className="flex flex-col gap-2">
              <h3>Sie sind auf der Warteliste</h3>
              <p>
                Ihre Anmeldung konnte nicht durcheführt werden. Sie sind aber
                nun auf der Warteliste und werden informiert, sobald ein Platz
                für Sie verfügbar ist.
              </p>
            </div>
          </div>
        )}
        {signUpResult?.data?.publicSignUp.result ===
          SignUpResult.ErrorWrongPassword && (
          <div className="flex gap-4 rounded-md border border-gray-200 bg-white p-6 md:p-8">
            <HeroIcon name="XCircle" className="mt-1 h-7 w-7 text-red-600" />
            <div className="flex flex-col gap-2">
              <h3 className="text-red-600">Falsches Passwort</h3>
              <p>
                {publicCompany?.functionalSettings?.errorTextWrongPassword?.trim() ||
                  `Sie konnten nicht für das Seminar angemeldet werden. Das
                eingegebene Passwort ist nicht gültig. Bitte probieren Sie es
                erneut mit einem anderen Passwort.`}
              </p>
            </div>
          </div>
        )}

        {(!signUpResult?.data?.publicSignUp ||
          signUpResult?.data?.publicSignUp.result ===
            SignUpResult.ErrorWrongPassword) && (
          <form
            className="grid grid-cols-2 gap-4 rounded-md border border-gray-200 bg-white p-6 md:p-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <DropdownStateWrapper
              options={[
                { text: 'Herr', value: PersonTitle.Mr },
                { text: 'Frau', value: PersonTitle.Ms },
              ]}
              name={'title'}
              label={'Anrede'}
              control={control}
              className="!w-auto"
              wrapperClassName="col-span-1"
              required={true}
              rules={{ required: true }}
            />
            <div className="col-span-1"></div>
            <InputStateWrapper
              name={'firstName'}
              control={control}
              label={'Vorname'}
              required={true}
              className="col-span-1"
              rules={{ required: true }}
            />
            <InputStateWrapper
              name={'lastName'}
              control={control}
              label={'Nachname'}
              required={true}
              className="col-span-1"
              rules={{ required: true }}
            />

            <InputStateWrapper
              name={'email'}
              control={control}
              label={'E-Mail'}
              wrapperClassName="col-span-2"
              rules={{ required: true }}
            />
            {dataSignUpFormElements?.publicCompanySignUpCustomFields.map(
              (ele) => {
                if (
                  ele.visibleIfField &&
                  ele.visibleIfValue !== allFields.fields[ele.visibleIfField]
                ) {
                  return;
                }
                let classes = '';
                if (ele.systemField) {
                  classes += 'hidden';
                }

                const commonProps = {
                  name: `fields.${ele.id}` as 'fields.${string}',
                  control,
                  className: classes,
                  wrapperClassName: 'col-span-2',
                  label: ele.name,
                };

                switch (ele.type) {
                  case SignUpCustomFieldType.Text:
                    return (
                      <InputStateWrapper
                        {...commonProps}
                        key={ele.id}
                        rules={{
                          required: ele.required,
                          validate: (val) => {
                            if (ele.required) {
                              if (
                                ele.validator ===
                                SignUpCustomFieldValidator.Iban
                              ) {
                                return isValidIBAN(val as string);
                              } else if (
                                ele.validator === SignUpCustomFieldValidator.Bic
                              ) {
                                return isValidBIC(val as string);
                              }
                            }
                            return true;
                          },
                        }}
                      />
                    );
                  case SignUpCustomFieldType.LongText:
                    return (
                      <TextareaStateWrapper
                        {...commonProps}
                        key={ele.id}
                        rules={{ required: ele.required }}
                      />
                    );
                  case SignUpCustomFieldType.Date:
                    return (
                      <DatePickerStateWrapper
                        {...commonProps}
                        key={ele.id}
                        rules={{ required: ele.required }}
                      />
                    );
                  case SignUpCustomFieldType.Check:
                    return (
                      <CheckboxStateWrapper
                        {...commonProps}
                        key={ele.id}
                        rules={{ required: ele.required }}
                      />
                    );
                  case SignUpCustomFieldType.Select:
                    return (
                      <DropdownStateWrapper
                        {...commonProps}
                        key={ele.id}
                        className={`!w-auto ${classes}`}
                        rules={{ required: ele.required }}
                        options={ele.options.map((option) => ({
                          text: option.displayValue,
                          value: option.value,
                        }))}
                      />
                    );
                  case SignUpCustomFieldType.Spacer:
                    return <hr key={ele.id} className="col-span-2" />;
                  case SignUpCustomFieldType.Label:
                    return (
                      <FieldWrapper className="col-span-2">
                        <Label key={ele.id}>{ele.name}</Label>
                      </FieldWrapper>
                    );
                }
              },
            )}

            {getCustomConditionalFields()}
            {data?.publicSeminar?.needsPassword && (
              <InputStateWrapper
                name={'password'}
                control={control}
                label={'Anmeldepasswort'}
                wrapperClassName="col-span-2"
                rules={{ required: true }}
              />
            )}
            <div className="col-span-2 mt-8 flex justify-end">
              <Button
                themeColor={'primary'}
                className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                disabled={!isValid || !isDirty || isSubmitting}
                title="Verbindlich anmelden"
              >
                {isSubmitting ? (
                  <Loader type={'pulsing'} className={'!text-white'} />
                ) : (
                  <HeroIcon
                    name="AcademicCap"
                    className="inline-block h-5 w-5"
                  />
                )}
                <div>Verbindlich anmelden</div>
              </Button>
            </div>
          </form>
        )}
      </div>
      {seminar && (
        <PublicDetailsSidebar
          seminar={seminar}
          showSignalLight={publicCompany?.visualSettings.showSignalLight}
        />
      )}
    </>
  );
};
