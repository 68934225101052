import { graphql } from '../../gql/index.ts';

const PublicCompanyQuery = graphql(/* GraphQL */ `
  query getPublicCompany {
    publicCompany {
      id
      companyName
      functionalSettings {
        registrationActive
        searchActive
        infoText
        errorTextWrongPassword
        waitlistActive
        passwordActive
        disableUserAccounts
        cancelSignUpActive
      }
      visualSettings {
        backToDashboardActive
        backToMainActive
        showImages
        primaryColor
        primaryTextColor
        pathToBanner
        showSignalLight
      }
      mailSettings {
        sendReminderSevenDays
        sendReminderOneDay
        sendReminderSevenDaysForLecturer
        sendReminderOneDayForLecturer
      }
      sites {
        title
        slug
        isFooterSite
        externalUrl
      }
      categories {
        id
        name
        children {
          id
          name
          children {
            id
            name
            children {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export { PublicCompanyQuery };
