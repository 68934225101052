import { Link } from 'wouter';
import { Helmet } from 'react-helmet-async';
import { ButtonGroup } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { canAccessAdminPanel } from './permissions.ts';

interface IAdminLayoutProps {
  children?: React.ReactNode;
}

export const PublicLayout: React.FC<IAdminLayoutProps> = ({ children }) => {
  const [publicCompany] = usePublicCompany();
  const { userInfo } = useUserInfo();

  const footerMenuSites = publicCompany?.sites.filter(
    (site) => site.isFooterSite,
  );

  const currentCompany =
    userInfo?.data?.companiesForSessionUser?.companiesForUser.find(
      (company) => company.id === publicCompany?.id,
    );

  const isAdminForCompany = currentCompany
    ? canAccessAdminPanel(currentCompany?.privileges)
    : false;

  return (
    <div
      id="custom-dialog-root"
      className="grid h-full grid-cols-[1fr_min(1280px,calc(100%-32px))_1fr] grid-rows-[70px_min-content_auto_minmax(auto,120px)] md:grid-cols-[1fr_min(1280px,calc(100%-64px))_1fr]"
      style={
        {
          // Use these variables for components we built ourselves
          '--theme-primary-color':
            publicCompany?.visualSettings.primaryColor || '#ff7f51',
          '--theme-primary-lighter-color':
            'color-mix(in srgb,var(--theme-primary-color),#fff 15%)',
          '--theme-primary-darker-color':
            'color-mix(in srgb,var(--theme-primary-color),#000 15%)',
          '--theme-text-color':
            publicCompany?.visualSettings.primaryTextColor || 'rgb(23,23,23)',
          '--theme-text-lighter-color':
            'color-mix(in srgb,var(--theme-text-color),#fff 60%)',
          // User these variables to override Kendo colors
          '--kendo-color-primary-active': 'var(--theme-primary-darker-color)',
          '--kendo-color-primary-emphasis':
            'var(--theme-primary-lighter-color)',
          '--kendo-color-primary':
            publicCompany?.visualSettings.primaryColor || '#ff7f51',
        } as React.CSSProperties
      }
    >
      <Helmet>
        <title>
          {publicCompany?.companyName || 'Seminarhaus'} -{' '}
          {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>
      <div className="z-10 col-span-3 grid grid-cols-subgrid border-b bg-white p-4 shadow-md">
        <div className="col-start-2 col-end-3 flex items-center justify-between">
          {publicCompany?.companyName && (
            <Link to="/" className="h-full w-auto">
              <h3>{publicCompany?.companyName}</h3>
            </Link>
          )}
          <div className="flex items-center gap-4">
            {userInfo?.data?.companiesForSessionUser?.user && (
              <span className="hidden md:block">
                Hallo,{' '}
                {userInfo?.data?.companiesForSessionUser?.user?.firstName}
              </span>
            )}
            <ButtonGroup>
              {userInfo?.data?.companiesForSessionUser?.user ? (
                <>
                  <Link
                    href={`/profile/myseminars`}
                    className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                  >
                    <HeroIcon
                      name={'AcademicCap'}
                      className="inline-block h-5 w-5 shrink-0"
                    />
                    <span className="hidden md:block">Profil</span>
                  </Link>
                  {isAdminForCompany && (
                    <Link
                      href={`/admin/`}
                      className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                    >
                      <HeroIcon
                        name={'Briefcase'}
                        className="inline-block h-5 w-5 shrink-0"
                      />
                      <span className="hidden md:block">Admin-Panel</span>
                    </Link>
                  )}
                  <a
                    href={`/api/auth/logout`}
                    className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                  >
                    <HeroIcon
                      name={'ArrowLeftStartOnRectangle'}
                      className="inline-block h-5 w-5 shrink-0"
                    />
                    <span className="hidden md:block">Abmelden</span>
                  </a>
                </>
              ) : !publicCompany?.functionalSettings?.disableUserAccounts ? (
                <>
                  <Link
                    href={`~/register`}
                    className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                  >
                    <HeroIcon
                      name={'ArrowLeftStartOnRectangle'}
                      className="inline-block h-5 w-5 shrink-0"
                    />
                    Registrieren
                  </Link>
                  <a
                    href={`/api/auth/login?successRef=${encodeURIComponent(window.location.href)}`}
                    className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                  >
                    <HeroIcon
                      name={'Key'}
                      className="inline-block h-5 w-5 shrink-0"
                    />
                    Login
                  </a>
                </>
              ) : (
                <></>
              )}
            </ButtonGroup>
          </div>
        </div>
      </div>
      {publicCompany?.visualSettings.pathToBanner && (
        <div className="col-start-1 col-end-4 lg:col-start-2 lg:col-end-3">
          <img
            src={'/api' + publicCompany?.visualSettings.pathToBanner}
            alt={'Bannergrafik von ' + publicCompany.companyName}
            className="w-full"
          />
        </div>
      )}
      <div className="col-start-2 col-end-3 grid h-full grid-cols-1 gap-6 py-6 md:grid-cols-12 md:py-8">
        {children}
      </div>
      <footer className="col-span-3 row-start-4 grid grid-cols-subgrid">
        <div className="col-start-2 flex h-full w-full flex-col items-center gap-4 border-t border-zinc-300 py-8">
          <div className="font-familjen text-black">
            Seminaranmeldung bereitgestellt durch BTS Web
          </div>
          {Boolean(footerMenuSites?.length) && (
            <nav>
              <ul className="flex h-full items-center justify-center gap-4 text-sm">
                {footerMenuSites?.map(({ title, slug, externalUrl }) => (
                  <li key={slug}>
                    <a
                      href={externalUrl || slug}
                      className="hover:text-black"
                      {...(externalUrl
                        ? {
                            target: '_blank',
                          }
                        : {})}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </footer>
    </div>
  );
};
