const stringToNumber = (s: string) => {
  for (var i = 0, h = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};

const getDistinctHueValues = (steps: number) => {
  const stepWidth = Math.floor(360 / Math.floor(steps));
  return [...Array(steps)].map((_, i) => (i + 1) * stepWidth);
};

const getHueValueFromString = (string: string) => {
  const values = getDistinctHueValues(20);
  const arrIdx =
    ((stringToNumber(string) % values.length) + values.length) % values.length;

  return values[arrIdx];
};

export const getHslColorFromString = (string: string) => {
  const hue = getHueValueFromString(string);
  return `hsl(${hue}deg 30% 60%)`;
};
