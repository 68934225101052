import { HeroIcon } from './HeroIcon';

export const BackButton = () => {
  return (
    <button
      className="flex cursor-pointer items-center gap-1.5 self-start border-b border-b-transparent text-zinc-800 hover:border-b-black hover:text-black"
      onClick={() => {
        history.back();
      }}
      title="Zurück"
    >
      <HeroIcon
        variant="outline"
        name={'ArrowLeftCircle'}
        className="inline-block h-5 w-5 shrink-0"
      />
      Zurück
    </button>
  );
};
