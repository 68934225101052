import { graphql } from '../gql/index.ts';
import { useMutation } from 'urql';
import { usePublicCompany } from './hooks/usePublicCompany.ts';

import { useForm } from 'react-hook-form';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { HeroIcon } from '../shared/HeroIcon.tsx';
import { PublicProfileSidebar } from './PublicProfileSidebar.tsx';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { Loader } from '@progress/kendo-react-indicators';
import { CheckboxStateWrapper } from '../admin/form-components/CheckboxStateWrapper.tsx';

interface MyNotificationsProps {
  companyId: string;
}

interface ProfileDataForm {
  allowSevenDaysReminder: boolean;
  allowOneDayReminder: boolean;
  allowSevenDaysReminderForLecturer: boolean;
  allowOneDayReminderForLecturer: boolean;
}

const UpdateProfileMutation = graphql(/* GraphQL */ `
  mutation updateMyProfile($companyId: String!, $user: PublicUpdateUserDto!) {
    publicUpdateUser(companyId: $companyId, user: $user)
  }
`);

export const MyNotifications: React.FC<MyNotificationsProps> = ({
  companyId,
}) => {
  const [publicCompany] = usePublicCompany();
  const { userInfo, refetchUserInfo } = useUserInfo();
  const [, updateProfile] = useMutation(UpdateProfileMutation);

  const { control, formState, handleSubmit, reset } = useForm<ProfileDataForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      allowOneDayReminder:
        userInfo?.data?.companiesForSessionUser.user.allowOneDayReminder ||
        false,
      allowSevenDaysReminder:
        userInfo?.data?.companiesForSessionUser.user.allowSevenDaysReminder ||
        false,
      allowOneDayReminderForLecturer:
        userInfo?.data?.companiesForSessionUser.user
          .allowOneDayReminderForLecturer || false,
      allowSevenDaysReminderForLecturer:
        userInfo?.data?.companiesForSessionUser.user
          .allowSevenDaysReminderForLecturer || false,
    },
  });

  const { isDirty, isSubmitting } = formState;

  return (
    <>
      <PublicProfileSidebar />
      <div className={`flex flex-col gap-4 md:col-span-9 md:col-start-4`}>
        <h2>Benachrichtigungen</h2>
        <div className="relative flex flex-col rounded-md border border-gray-200 bg-white p-6 md:p-8">
          <form
            className="flex flex-col gap-8"
            onSubmit={handleSubmit(async (formData) => {
              if (isDirty) {
                updateProfile({
                  companyId,
                  user: formData,
                }).then(() => {
                  refetchUserInfo();
                  reset();
                });
              }
            })}
          >
            {(publicCompany?.mailSettings?.sendReminderSevenDays ||
              publicCompany?.mailSettings?.sendReminderOneDay) && (
              <div className="flex flex-col gap-2">
                <Label className="k-form-label col-span-2 mb-1 font-semibold">
                  Als Teilnehmer
                </Label>

                {publicCompany?.mailSettings?.sendReminderOneDay && (
                  <CheckboxStateWrapper
                    name={'allowOneDayReminder'}
                    control={control}
                    label={'Erinnerung 24h vor einem Seminar schicken'}
                    wrapperClassName=""
                  />
                )}
                {publicCompany?.mailSettings?.sendReminderSevenDays && (
                  <CheckboxStateWrapper
                    name={'allowSevenDaysReminder'}
                    control={control}
                    label={'Erinnerung 7 Tage vor einem Seminar schicken'}
                  />
                )}
              </div>
            )}

            {(publicCompany?.mailSettings?.sendReminderOneDayForLecturer ||
              publicCompany?.mailSettings
                ?.sendReminderSevenDaysForLecturer) && (
              <div className="flex flex-col gap-2">
                <Label className="k-form-label col-span-2 mb-1 font-semibold">
                  Als Dozent
                </Label>

                {publicCompany?.mailSettings?.sendReminderOneDayForLecturer && (
                  <CheckboxStateWrapper
                    name={'allowOneDayReminder'}
                    control={control}
                    label={'Erinnerung 24h vor einem Seminar schicken'}
                    wrapperClassName=""
                  />
                )}
                {publicCompany?.mailSettings
                  ?.sendReminderSevenDaysForLecturer && (
                  <CheckboxStateWrapper
                    name={'allowSevenDaysReminder'}
                    control={control}
                    label={'Erinnerung 7 Tage vor einem Seminar schicken'}
                  />
                )}
              </div>
            )}

            <div className="pt-4">
              <Button
                disabled={isSubmitting || !isDirty}
                type={'submit'}
                themeColor={'primary'}
                className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
                title="Speichern"
              >
                {isSubmitting ? (
                  <Loader type={'pulsing'} className={'!text-white'} />
                ) : (
                  <HeroIcon name="CheckCircle" className="block h-5 w-5" />
                )}
                <div>Speichern</div>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
