import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql } from '../gql';
import { useMutation, useQuery } from 'urql';
import { SeminarCard } from './SeminarCard.tsx';
import { usePublicCompany } from './hooks/usePublicCompany.ts';
import {
  ModulePrivileges,
  PublicSeminarPageFieldType,
} from '../gql/graphql.ts';
import { PublicDetailsSidebar } from './PublicDetailsSidebar.tsx';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';
import { cloneAndRemoveTypename } from './util.ts';
import { useUserInfo } from './hooks/useUserInfo.ts';
import { ConditionalWrapper } from '../shared/ConditionalWrapper.tsx';
import { PublicSeminarFilesTab } from './PublicSeminarFilesTab.tsx';
import { PublicSeminarSignUpsTab } from './PublicSeminarSignUpsTab.tsx';
import { PublicSeminarParticipantsTab } from './PublicSeminarParticipantsTab.tsx';
import { PublicSeminarCancelSignUpModal } from './PublicSeminarCancelSignUpModal.tsx';
import { BackButton } from '../shared/BackButton.tsx';

const PublicSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSeminar(
    $seminarId: String
    $btsSeminarId: String
    $companyId: String!
  ) {
    publicSeminar(
      seminarId: $seminarId
      btsSeminarId: $btsSeminarId
      companyId: $companyId
    ) {
      id
      title
      subtitle
      seminarNumber
      startDate
      endDate
      dateText
      description
      locationText
      registrationDate
      price
      priceText
      price2
      priceText2
      price3
      priceText3
      price4
      priceText4
      isBookedOut
      isAlmostBookedOut
      canceled
      files {
        blobId
        accessPermissionLevel
        specificSignUpId
        pathToFile
        uploadedAt
        name
        size
      }
      body {
        type
        html
      }
      lecturers {
        name
        id
        jobTitle
        vita
        pathToProfileImage
      }
      categories {
        name
      }
      signUps {
        signUpId
        firstName
        lastName
        email
        canceled
      }
      currentUserIsLecturer
      images
    }
  }
`);

const PublicSignUpsForSeminarQuery = graphql(/* GraphQL */ `
  query getPublicSignUpsSeminar($seminarId: String!, $companyId: String!) {
    signUpsForSeminar(seminarId: $seminarId, companyId: $companyId) {
      firstName
      lastName
      email
      id
      linkedToAccount
    }
  }
`);

const CancelSignUpMutation = graphql(/* GraphQL */ `
  mutation publicCancelSignUp($signUpId: Float!, $companyId: String!) {
    publicCancelSignUp(signUpId: $signUpId, companyId: $companyId)
  }
`);

interface PublicSeminarProps {
  seminarId?: string;
  btsSeminarId?: string;
  companyId: string;
}

export const PublicSeminar: React.FC<PublicSeminarProps> = ({
  seminarId,
  btsSeminarId,
  companyId,
}) => {
  const [{ data }, refresh] = useQuery({
    query: PublicSeminarQuery,
    variables: {
      companyId,
      seminarId,
      btsSeminarId,
    },
    pause:
      companyId === undefined ||
      companyId === null ||
      ((seminarId === undefined || seminarId === null) &&
        (btsSeminarId === undefined || btsSeminarId === null)),
  });

  const { userInfo } = useUserInfo();

  useEffect(() => {
    if (!seminarId && btsSeminarId && data?.publicSeminar.id) {
      window.history.replaceState(
        null,
        '',
        `/seminar/${data?.publicSeminar.id}`,
      );
    }
  }, [seminarId, btsSeminarId, data?.publicSeminar.id]);

  const currentUserCompany =
    userInfo?.data?.companiesForSessionUser?.companiesForUser.find(
      (company) => company.id === companyId,
    );

  const userHasParticipantListPrivilege = Boolean(
    currentUserCompany?.privileges.includes(
      ModulePrivileges.UseParticipantList,
    ),
  );

  const [{ data: dataSignUps }] = useQuery({
    query: PublicSignUpsForSeminarQuery,
    variables: {
      companyId,
      seminarId: data?.publicSeminar.id as string,
    },
    pause:
      !data?.publicSeminar?.id ||
      (!data?.publicSeminar.currentUserIsLecturer &&
        !userHasParticipantListPrivilege),
  });
  const activeSignUps =
    data?.publicSeminar.signUps.filter((s) => !s.canceled) ?? [];

  const [, cancelSignUp] = useMutation(CancelSignUpMutation);

  const { publicSeminar: seminar } = data || {};
  const [showCancelSignUpModalSignUpId, setShowCancelSignUpModalSignUpId] =
    useState<number | null>(null);

  const [publicCompanyData] = usePublicCompany();

  const getHtmlElement = (
    type: PublicSeminarPageFieldType,
    content: string,
  ) => {
    const commonBoxClasses = 'border px-4 py-3 rounded relative my-2';
    // Would love to replace this with an actual React component <Alert>, but this is raw html injected into the DOM, so can't use React components here
    const mapping: Record<string, string> = {
      error: `<div class="bg-red-100 border-red-400 text-red-700 prose-headings:text-red-700 ${commonBoxClasses}">${content}</div>`,
      info: `<div class="bg-sky-100 border-sky-400 text-sky-700 prose-headings:text-sky-700 ${commonBoxClasses}">${content}</div>`,
      warning: `<div class="bg-yellow-100 border-yellow-400 text-yellow-700 prose-headings:text-yellow-700 ${commonBoxClasses}">${content}</div>`,
      notice: `<div class="bg-slate-100 border-slate-400 text-slate-700 prose-headings:text-slate-700  ${commonBoxClasses}">${content}</div>`,
      heading: `<div class="font-bold text-3xl my-2">${content}</div>`,
      normal: `<div>${content}</div>`,
    };
    return mapping[type?.toLowerCase() || 'normal'];
  };

  const bodyHtml = seminar?.body
    ?.map(
      (item) => item.type && item.html && getHtmlElement(item.type, item.html),
    )
    .filter(Boolean)
    .join('');

  const hasWaitlist =
    publicCompanyData?.functionalSettings?.waitlistActive &&
    seminar?.isBookedOut;

  const [selected, setSelected] = React.useState<number>(0);

  const isBeforeRegistrationDate = seminar?.registrationDate
    ? new Date(seminar?.registrationDate) > new Date()
    : true;

  const isBeforeEndOfSeminar = new Date(seminar?.endDate) > new Date();

  const userIsLecturerForThisSeminar = Boolean(
    data?.publicSeminar.currentUserIsLecturer,
  );
  const userIsSignedUp = Boolean(activeSignUps.length);
  const seminarHasFiles = Boolean(data?.publicSeminar.files.length);

  const userHasFilePrivileges = Boolean(
    currentUserCompany?.privileges?.includes(
      ModulePrivileges.CanAccessAllSpecificParticipantFiles,
    ) ||
      currentUserCompany?.privileges?.includes(
        ModulePrivileges.CanAccessAllParticipantsFiles,
      ) ||
      currentUserCompany?.privileges?.includes(
        ModulePrivileges.CanAccessAllLecturerFiles,
      ),
  );

  const userHasFileOrSeminarListPrivilege = Boolean(
    userHasFilePrivileges || userHasParticipantListPrivilege,
  );

  const userHasMultipleSignUps = (activeSignUps.length ?? 0) > 1;

  const userHasTabLayout =
    userHasMultipleSignUps ||
    userIsLecturerForThisSeminar ||
    (userIsSignedUp && seminarHasFiles) ||
    userHasFileOrSeminarListPrivilege;

  return (
    <>
      <Helmet>
        <title>
          {seminar?.title || 'Seminar'} - {import.meta.env.VITE_APP_TITLE}
        </title>
      </Helmet>
      <div className="col-span-1 flex flex-col gap-4 md:col-span-9">
        <BackButton />
        <ConditionalWrapper
          condition={userHasTabLayout}
          wrapper={(children) => {
            const tabStripTabClasses = '!p-0';

            return (
              <TabStrip
                selected={selected}
                onSelect={(e: TabStripSelectEventArguments) => {
                  setSelected(e.selected);
                }}
                animation={false}
                className="[&_.k-active_.k-link]:!text-gray-500 [&_.k-link]:!text-bts-theme-primary focus:[&_.k-tabstrip-item]:!shadow-none"
              >
                <TabStripTab
                  contentClassName={tabStripTabClasses}
                  title="Beschreibung"
                >
                  {children}
                </TabStripTab>
                {userHasMultipleSignUps && (
                  <TabStripTab
                    contentClassName={tabStripTabClasses + ' md:!p-0'}
                    title={`Anmeldungen (${activeSignUps.length})`}
                  >
                    <PublicSeminarSignUpsTab
                      signUps={activeSignUps}
                      onClickCancel={
                        publicCompanyData?.functionalSettings
                          ?.cancelSignUpActive
                          ? setShowCancelSignUpModalSignUpId
                          : undefined
                      }
                    />
                  </TabStripTab>
                )}
                {(userIsLecturerForThisSeminar ||
                  userHasParticipantListPrivilege) && (
                  <TabStripTab
                    contentClassName={tabStripTabClasses + ' md:!p-0'}
                    title={`Teilnehmer ${dataSignUps?.signUpsForSeminar.length ? `(${dataSignUps?.signUpsForSeminar.length})` : ''}`}
                  >
                    <PublicSeminarParticipantsTab
                      dataPublicSeminar={data}
                      dataSignUp={dataSignUps}
                      emailCurrentUser={
                        userInfo?.data?.companiesForSessionUser.user.email
                      }
                    />
                  </TabStripTab>
                )}
                {(userIsLecturerForThisSeminar ||
                  (userIsSignedUp && seminarHasFiles) ||
                  userHasFilePrivileges) && (
                  <TabStripTab
                    contentClassName={tabStripTabClasses + ' md:!p-0'}
                    title={`Dateien ${data?.publicSeminar?.files?.length ? `(${data?.publicSeminar.files?.length})` : ''}`}
                  >
                    <PublicSeminarFilesTab
                      seminarId={data?.publicSeminar.id as string}
                      companyId={companyId}
                      participants={dataSignUps?.signUpsForSeminar ?? []}
                      files={
                        data?.publicSeminar.files &&
                        cloneAndRemoveTypename(data?.publicSeminar.files)
                      }
                      showUploadSection={
                        userIsLecturerForThisSeminar || userHasFilePrivileges
                      }
                      refreshData={refresh}
                      showSpecificParticipantFilesOption={currentUserCompany?.privileges?.includes(
                        ModulePrivileges.CanAccessAllSpecificParticipantFiles,
                      )}
                      showAllParticipantFilesOption={
                        userIsLecturerForThisSeminar ||
                        currentUserCompany?.privileges?.includes(
                          ModulePrivileges.CanAccessAllParticipantsFiles,
                        )
                      }
                      showAllLecturerFilesOption={
                        userIsLecturerForThisSeminar ||
                        currentUserCompany?.privileges?.includes(
                          ModulePrivileges.CanAccessAllLecturerFiles,
                        )
                      }
                    />
                  </TabStripTab>
                )}
              </TabStrip>
            );
          }}
        >
          <SeminarCard
            singleView
            images={
              publicCompanyData?.visualSettings?.showImages
                ? data?.publicSeminar.images.map((path) => '/api' + path)
                : undefined
            }
            id={seminar?.seminarNumber}
            title={seminar?.title}
            description={bodyHtml}
            subtitle={seminar?.subtitle}
            category={
              seminar?.categories?.find((c) => Boolean(c.name.trim()))?.name
            }
            isSignedUp={Boolean(activeSignUps.length)}
            isSignUpCanceled={Boolean(
              seminar?.signUps.length && !activeSignUps.length,
            )}
            signUpLink={
              publicCompanyData?.functionalSettings.registrationActive &&
              isBeforeEndOfSeminar &&
              isBeforeRegistrationDate &&
              !seminar?.canceled &&
              (!seminar?.isBookedOut || hasWaitlist)
                ? '/sign-up'
                : undefined
            }
            signUpLinkCaption={
              hasWaitlist
                ? activeSignUps.length
                  ? 'Weitere Anmeldung auf der Warteliste'
                  : 'Auf die Warteliste'
                : activeSignUps.length
                  ? 'Weitere Person anmelden'
                  : 'Anmelden'
            }
            signUpLinkLogo={hasWaitlist ? 'QueueList' : 'AcademicCap'}
            onCancelSignUpClick={
              activeSignUps.length === 1 &&
              publicCompanyData?.functionalSettings.cancelSignUpActive
                ? () => {
                    setShowCancelSignUpModalSignUpId(activeSignUps[0].signUpId);
                  }
                : undefined
            }
            tabbed={userHasTabLayout}
          />
        </ConditionalWrapper>
      </div>
      {seminar && (
        <PublicDetailsSidebar
          seminar={seminar}
          showSignalLight={
            publicCompanyData?.visualSettings.showSignalLight &&
            !seminar.canceled
          }
        />
      )}
      {showCancelSignUpModalSignUpId && (
        <PublicSeminarCancelSignUpModal
          onClose={() => {
            setShowCancelSignUpModalSignUpId(null);
          }}
          startDate={new Date(seminar?.startDate)}
          onClickCancel={() => {
            cancelSignUp({
              companyId: companyId,
              signUpId: showCancelSignUpModalSignUpId,
            }).then(() => {
              refresh();
              setShowCancelSignUpModalSignUpId(null);
            });
          }}
        />
      )}
    </>
  );
};
