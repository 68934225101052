import { ModulePrivileges } from '../gql/graphql';

export const permissionTable: {
  rolePrivileges: Record<
    string,
    { displayName: string; privileges: ModulePrivileges[] }
  >;
  adminPanelPrivileges: ModulePrivileges[];
} = {
  rolePrivileges: {
    member: {
      displayName: 'Teilnehmer',
      privileges: [ModulePrivileges.Member],
    },
    lecturer: {
      displayName: 'Dozent',
      privileges: [ModulePrivileges.Member, ModulePrivileges.Lecturer],
    },
    company_employee: {
      displayName: 'Institutsmitarbeiter',
      privileges: [
        ModulePrivileges.Member,
        ModulePrivileges.Seminar,
        ModulePrivileges.Site,
        ModulePrivileges.Categories,
        ModulePrivileges.CanAccessAllLecturerFiles,
        ModulePrivileges.CanAccessAllParticipantsFiles,
        ModulePrivileges.CanAccessAllSpecificParticipantFiles,
        ModulePrivileges.UseParticipantList,
      ],
    },
    admin: {
      displayName: 'Admin',
      privileges: [
        ModulePrivileges.Member,
        ModulePrivileges.User,
        ModulePrivileges.Categories,
        ModulePrivileges.Company,
        ModulePrivileges.Flags,
        ModulePrivileges.Seminar,
        ModulePrivileges.Site,
        ModulePrivileges.PublicSeminarPageFields,
        ModulePrivileges.SignUpForm,
        ModulePrivileges.Lecturer,
        ModulePrivileges.CanAccessAllLecturerFiles,
        ModulePrivileges.CanAccessAllParticipantsFiles,
        ModulePrivileges.CanAccessAllSpecificParticipantFiles,
        ModulePrivileges.UseParticipantList,
      ],
    },
  },
  adminPanelPrivileges: [
    ModulePrivileges.User,
    ModulePrivileges.Categories,
    ModulePrivileges.Company,
    ModulePrivileges.Flags,
    ModulePrivileges.Seminar,
    ModulePrivileges.Site,
    ModulePrivileges.PublicSeminarPageFields,
    ModulePrivileges.SignUpForm,
  ],
};

export const canAccessAdminPanel = (privileges: ModulePrivileges[]) => {
  return privileges.some((p) =>
    permissionTable.adminPanelPrivileges.includes(p),
  );
};

export const privilegesToRoleDisplayName = (privileges: ModulePrivileges[]) => {
  const roles = Object.entries(permissionTable.rolePrivileges);

  for (const [, roleDef] of roles) {
    if (
      JSON.stringify(privileges.sort()) ===
      JSON.stringify(roleDef.privileges.sort())
    ) {
      return roleDef.displayName;
    }
  }
};
