import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import { graphql } from '../../gql';
import {
  getSelectedState,
  Grid,
  GridColumn,
  GridFilterChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridNoRecords,
  GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { Link } from 'wouter';
import { PageTitle } from '../PageTitle.tsx';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import {
  filterBy,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { UserOrLecturerDto } from '../../gql/graphql.ts';
import { Loader } from '@progress/kendo-react-indicators';
import { Chip } from '@progress/kendo-react-buttons';
import { privilegesToRoleDisplayName } from '../../public/permissions.ts';

interface UsersPageProps {
  companyId: string;
}

const UsersQuery = graphql(/* GraphQL */ `
  query usersAndLecturers($companyId: String!) {
    usersAndLecturersForCompany(companyId: $companyId) {
      lecturerId
      userId
      email
      firstName
      lastName
      jobTitle
      hasAccount
      privileges
    }
  }
`);

const DeleteUserMutation = graphql(/* GraphQL */ `
  mutation deleteUser($companyId: String!, $userId: String!) {
    deleteUser(companyId: $companyId, userId: $userId)
  }
`);

const InviteLecturersMutation = graphql(/* GraphQL */ `
  mutation inviteLecturers($companyId: String!, $lecturerIds: [String!]!) {
    inviteLecturers(companyId: $companyId, lecturerIds: $lecturerIds)
  }
`);

export const UsersPage: React.FC<UsersPageProps> = ({ companyId }) => {
  // TODO: Error handling
  const [{ data }, reExecuteUsersQuery] = useQuery({
    query: UsersQuery,
    variables: { companyId },
    pause: companyId === undefined || companyId === null,
  });

  const [filter, setFilter] = useState<CompositeFilterDescriptor>({
    logic: 'and',
    filters: [],
  });

  const [spinnerRowIndex, setSpinnerRowIndex] = useState<number | null>(null);
  const [selectedState, setSelectedState] = useState<
    Record<string, boolean | number[]>
  >({});

  const [, executeDeleteUser] = useMutation(DeleteUserMutation);
  const [inviteLecturersResult, executeInviteLecturers] = useMutation(
    InviteLecturersMutation,
  );

  const [page, setPage] = useState({
    skip: 0,
    take: 10,
  });

  const idGetter = getter('combinedId');

  const onSelectionChange = (event: GridSelectionChangeEvent) => {
    setSelectedState((prevState) =>
      getSelectedState({
        event,
        selectedState: prevState,
        dataItemKey: 'combinedId',
      }),
    );
  };

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: HTMLInputElement = event.syntheticEvent
        .target as HTMLInputElement;
      const checked = checkboxElement.checked as boolean;
      const newSelectedState: Record<string, boolean> = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    [idGetter],
  );

  const filterdData = filterBy(
    (data?.usersAndLecturersForCompany as UserOrLecturerDto[]) ?? [],
    filter,
  );

  const deleteUser = (userId: string) => {
    executeDeleteUser({ companyId, userId }).then(() => reExecuteUsersQuery());
  };

  const inviteLecturers = (lecturerIds: string[]) => {
    return executeInviteLecturers({ companyId, lecturerIds }).then(() =>
      reExecuteUsersQuery(),
    );
  };

  const displayData = filterdData.map((item) => {
    const combinedId = `${item.lecturerId || ''}${item.userId || ''}`;
    return {
      ...item,
      combinedId: combinedId,
    };
  });

  return (
    <>
      <PageTitle title="Personen" />

      <div className={'mb-4 flex justify-end'}>
        <Link
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          href={'/users/new'}
        >
          <HeroIcon name="UserPlus" className="h-5 w-5" />
          Neuer Benutzer
        </Link>
      </div>

      <Grid
        className="h-auto [&_.k-grid-norecords-template]:border-0"
        data={displayData
          .slice(page.skip, page.take + page.skip)
          .map((item) => ({
            ...item,
            selected: selectedState[item.combinedId],
          }))}
        onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        filterable={true}
        filter={filter}
        take={page?.take}
        skip={page?.skip}
        total={filterdData.length}
        onPageChange={(event) => {
          setPage(event.page);
        }}
        selectable={{ enabled: true }}
        selectedField="selected"
        pageable
      >
        <GridNoRecords>Keine Personen gefunden</GridNoRecords>
        <GridColumn filterable={false} field={'selected'} width="40px" />
        <GridColumn field="email" title="Email" />
        <GridColumn field="firstName" title="Vorname" />
        <GridColumn field="lastName" title="Nachname" />
        <GridColumn
          filterable={false}
          title="Typ"
          width={'130px'}
          cell={(props) => {
            let userType;
            if (!props.dataItem.hasAccount) {
              userType = 'Dozent (BTS)';
            } else {
              userType = privilegesToRoleDisplayName(props.dataItem.privileges);
            }
            return <td>{userType && <Chip text={userType} />}</td>;
          }}
        />
        <GridColumn
          filterable={true}
          title="Aktionen"
          filterCell={() => {
            const selectionHasUser = Object.entries(selectedState).some(
              ([key, value]) =>
                value &&
                Boolean(
                  displayData.find((item) => item.combinedId === key)?.userId,
                ),
            );

            const selectionCount =
              Object.values(selectedState).filter(Boolean).length;

            const selectionIsEmpty =
              !Object.values(selectedState).filter(Boolean).length;

            return (
              <div className="flex h-[38px] w-full items-center gap-2 leading-[38px]">
                <button
                  className={`k-button k-button-md ${selectionCount > 0 ? 'k-button-solid k-button-solid-secondary' : 'k-button-outline k-button-outline-secondary'} k-rounded-md ${inviteLecturersResult.fetching ? 'k-disabled' : ''}`}
                  onClick={() => {
                    const inviteeLecturerIds = Object.entries(selectedState)
                      .map(([key, value]) =>
                        value
                          ? displayData.find((item) => item.combinedId === key)
                              ?.lecturerId
                          : null,
                      )
                      .filter(Boolean) as string[];

                    setSpinnerRowIndex(0);

                    inviteLecturers(inviteeLecturerIds).then(() => {
                      setSpinnerRowIndex(null);
                      setSelectedState({});
                    });
                  }}
                  title="Einladung an alle verschicken, um ein Konto zu erstellen"
                  disabled={
                    selectionIsEmpty ||
                    selectionHasUser ||
                    inviteLecturersResult.fetching
                  }
                >
                  {inviteLecturersResult.fetching &&
                  spinnerRowIndex !== null &&
                  spinnerRowIndex === 0 ? (
                    <Loader
                      size="small"
                      type={'infinite-spinner'}
                      className={'!text-bts-theme-text-lighter'}
                    />
                  ) : (
                    <HeroIcon name="Envelope" className="block h-5 w-5" />
                  )}
                </button>
                {Boolean(selectionCount) && <p>({selectionCount})</p>}
              </div>
            );
          }}
          width={'170px'}
          cell={(props) => {
            const selectionCount =
              Object.values(selectedState).filter(Boolean).length;
            return (
              <td className={'k-button-link-secondary flex h-full space-x-2'}>
                {props.dataItem.hasAccount ? (
                  <>
                    <div
                      className={`k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md !text-[#6c757d] hover:!text-white ${props.dataItem.hasAccount ? '' : 'k-disabled'}`}
                    >
                      <Link
                        href={`/users/edit/${props.dataItem.userId}`}
                        title="Benutzer bearbeiten"
                      >
                        <HeroIcon name="Pencil" className="block h-5 w-5" />
                      </Link>
                    </div>

                    <button
                      className="k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md"
                      onClick={() => deleteUser(props.dataItem.userId)}
                      title="Benutzer löschen"
                    >
                      <HeroIcon name="Trash" className="block h-5 w-5" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`k-button k-button-md k-button-outline k-button-outline-secondary k-rounded-md ${inviteLecturersResult.fetching ? 'k-disabled' : ''}`}
                    onClick={() => {
                      setSpinnerRowIndex(props.dataIndex + 1);
                      inviteLecturers([props.dataItem.lecturerId]).then(() => {
                        setSpinnerRowIndex(null);
                      });
                    }}
                    title="Einzelne Einladung verschicken um ein Konto zu erstellen"
                    disabled={
                      inviteLecturersResult.fetching || selectionCount !== 0
                    }
                  >
                    {inviteLecturersResult.fetching &&
                    spinnerRowIndex &&
                    props.dataIndex === spinnerRowIndex - 1 ? (
                      <Loader
                        size="small"
                        type={'infinite-spinner'}
                        className={'!text-bts-theme-text-lighter'}
                      />
                    ) : (
                      <HeroIcon name="Envelope" className="block h-5 w-5" />
                    )}
                  </button>
                )}
              </td>
            );
          }}
        />
      </Grid>
    </>
  );
};
