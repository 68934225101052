import { useMutation } from 'urql';
import React from 'react';
import { graphql } from '../../gql/index.ts';
import { useLocation } from 'wouter';
import { PageTitle } from '../PageTitle.tsx';
import { Fieldset } from '../form-components/Fieldset.tsx';
import { InputStateWrapper as HookedInput } from '../form-components/InputStateWrapper.tsx';
import { Button } from '@progress/kendo-react-buttons';
import { HeroIcon } from '../../shared/HeroIcon.tsx';
import { useForm } from 'react-hook-form';
import { CreateCompanyDto } from '../../gql/graphql.ts';

const AddCompanyMutation = graphql(/* GraphQL */ `
  mutation createCompany($company: CreateCompanyDto!) {
    createCompany(company: $company) {
      id
    }
  }
`);

export const CompanyNewPage: React.FC = () => {
  const [, addCompany] = useMutation(AddCompanyMutation);
  const [, setLocation] = useLocation();

  const { control, formState, handleSubmit } = useForm<CreateCompanyDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { isValid, isDirty } = formState;

  const onFormSubmit = (values: CreateCompanyDto) => {
    addCompany({
      company: values,
    }).then(() => {
      setLocation('/');
    });
  };

  return (
    <>
      <PageTitle title="Seminarhaus erstellen" />
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className="k-form k-form-md relative flex w-full flex-col rounded-md bg-white shadow lg:w-full"
      >
        <Fieldset
          legend="Basisdaten"
          description="Achtung: Diese Daten können im Nachhinein nicht mehr bearbeitet werden."
        >
          <div className="flex flex-col gap-4 md:col-span-9 [&_.k-form-field]:!mt-0">
            <HookedInput
              name={'name'}
              label={'Name'}
              placeholder="Mein Seminarhaus"
              control={control}
              rules={{ required: true }}
            />
            <HookedInput
              name={'domain'}
              label={'Domain'}
              placeholder="bts.seminarhaus.de"
              control={control}
              rules={{ required: true }}
            />
          </div>
        </Fieldset>

        <div className="k-form-buttons !mt-0 justify-end rounded-md bg-gray-50 !px-8 !py-3 !text-right">
          <Button
            type={'submit'}
            themeColor={'primary'}
            className="!shadow-sm [&>span.k-button-text]:flex [&>span.k-button-text]:items-center [&>span.k-button-text]:gap-1"
            disabled={!isValid || !isDirty}
            title="Speichern"
          >
            <HeroIcon name="CheckCircle" className="block h-5 w-5" />
            <div>Speichern</div>
          </Button>
        </div>
      </form>
    </>
  );
};
